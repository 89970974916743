import React, {useEffect, useState, useRef} from 'react';
import {CSSTransition} from "react-transition-group";
import FileListPlaceholder from "../../../components/placeholders/fileListPlaceholder";
import EmployeeActivityList from "../../../components/lists/employeeActivityList";
import axios from "axios";
import {useStoreState} from "easy-peasy";
import InfiniteScroll from "react-infinite-scroller";
import ActivityPopup from "../../../components/popups/activityPopup";
import DownloadCsv from "../../../components/DownloadCsv";

export default function EmployeeActivity({accentColor}){

	const [loading,setLoading] = useState(true);
	const [selectedActivity, setSelectedActivity] = useState(null);
	const [showActivityPopup,setShowActivityPopup] = useState(false);
	const [activities,setActivities] = useState([]);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const user = useStoreState(state=>state.user.user);
	const [currentPage,setCurrentPage] = useState(1);
	const [totalPages,setTotalPages] = useState(1);
	const [isScrollLoading,setIsScrollLoading] = useState(false);

	const viewActivity = (activity) =>{
		setSelectedActivity(activity);
		setShowActivityPopup(true);
	};
	const closeActivityPopup = () =>{
		setShowActivityPopup(false);
	};

	const scrollLoad = (page) =>{
		setIsScrollLoading(true);
		let queryString = '?page='+page;
		setCurrentPage(page);
		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/activity' + queryString).then(response=>{
			setActivities([...activities,...response.data.activity]);
			setLoading(false);
			setTotalPages(response.data.meta.pages);
			setTimeout(()=>{setIsScrollLoading(false);},0)
		}).catch(e=>{});
	};

	useEffect(()=>{
		let cancelToken = axios.CancelToken.source();
		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/activity').then(response=>{
			setActivities(response.data.activity);
			setCurrentPage(response.data.meta.page);
			setTotalPages(response.data.meta.pages);
			setLoading(false);
		}).catch(e=>{});
		return ()=>{
			cancelToken.cancel('');
		}
	},[]);

	return (
		<>
			{showActivityPopup && <ActivityPopup activity={selectedActivity} closeHandler={closeActivityPopup} /> }
			<div className={'content-header'}>
				<div className={'title'} style={{width: '100%'}}>
					<h1 style={{color:accentColor}} >
						Activity
					</h1>
					<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
						<a className={'sub-header'}  href={'#'}>
							Review and Download Activity
						</a>
						<DownloadCsv
							url={`/organizations/${activeOrganization.organization_id}/users/${user.user_id}/payment-history-csv`}
							label="Download Payment History"
							downloadName="Employee Payments.csv"
							className={'sub-header'}
							style={{cursor: 'pointer'}}
						/>
					</div>
				</div>
			</div>
			<CSSTransition
				in={loading}
				timeout={{
					appear: 500,
					enter: 300,
					exit: 0,
				}}
				classNames="fade"
				unmountOnExit
			>
				<FileListPlaceholder />
			</CSSTransition>
			<CSSTransition
				in={!loading}
				timeout={{
					enter: 300,
					exit: 0,
				}}
				classNames="fade"
				unmountOnExit
			>
				<InfiniteScroll
					pageStart={1}
					loadMore={isScrollLoading?null:scrollLoad}
					initialLoad={false}
					hasMore={(currentPage<totalPages)}
					loader={<div className="loader" key={0}>Loading ...</div>}
				>
				<EmployeeActivityList accentColor={accentColor} activities={activities} linkClass={'elections'} viewHandler={viewActivity}/>
				</InfiniteScroll>
			</CSSTransition>
		</>
	)
}
