import Popup from "./popup";
import React, {useEffect, useState, useCallback, useMemo} from 'react';
import axios from "axios";
import AccountSelector from "../accountSelector";
import {sleep, uniqid, nl2br} from "../../_helpers";
import { SPINWHEEL_MODULES } from "../../hooks/useSpinwheelDim";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function SpinwheelLoansPopup({closeHandler, triggerBankLogin, authToken, connectionType, organization}){

    const [directLoans,setDirectLoans] = useState([]);
    const [idConnectLoans,setIdConnectLoans] = useState([]);
    const [updateKey,setUpdateKey] = useState(Math.random());
    const [selectedAccount,setSelectedAccount] = useState(null);
    const [loading,setLoading] = useState(true);
    const activeOrganization = useStoreState(state=>state?.user?.activeOrganization);
    const [accountNumberUpdate, setAccountNumberUpdate] = useState('');
    const [spinwheelServicers, setSpinwheelServicers] = useState([]);
    const [refreshTried,setRefreshTried] = useState(false);

    const setShowContact = useStoreActions(actions=>actions.modals.setShowContact);
    useEffect(()=>{
        const cancelTokenSource = axios.CancelToken.source();
        const fetchLoans= async () =>{
            await getLoans();
        }
        fetchLoans().catch(e=>{console.log(e)})
        getSpinwheelServicers();
        return ()=>{
            cancelTokenSource.cancel();
        }
    },[])

    const selectAccount = (account) =>{
        console.log(account);
        setDirectLoans(prevState => prevState.map(loan=>{
                loan.selected = loan.account_id === account.account_id;
                return loan;
            })
        );
        setIdConnectLoans(prevState =>prevState.map(loan=>{
                loan.selected = loan.account_id === account.account_id;
                return loan;
            })
        );
        setSelectedAccount(account);
    }
    const helpHandler = () =>{
        setShowContact(true);
        closeHandler();
    }



    const getLoans = async (count = 0, refresh = false) => {

        if(count>10){
            console.log('too many attempts');
            setLoading(false);
            return;
        }
        let orgId = organization?.organization_id || activeOrganization?.organization_id;
        let url = '/spinwheel/loans?organization_id='+orgId;
        if(refresh) {
            setRefreshTried(true);
            url += '&refresh';
        }
        
        await sleep(5000);
        const response = await axios.get(url, { headers:{'Auth-token': authToken}}).catch(e=>{
            console.log(e);
            setLoading(false);
        });
        let connected = false;

        if(connectionType === SPINWHEEL_MODULES.LOAN_SERVICERS_LOGIN ){
            if(response?.data?.direct_loans){
                setDirectLoans(mapLoans(response.data.direct_loans));
                connected = true;
            }
        }
        if(connectionType === SPINWHEEL_MODULES.ID_CONNECT){
            if(connectionType === SPINWHEEL_MODULES.ID_CONNECT && response?.data?.id_connected_loans){
                setIdConnectLoans(mapLoans(response.data.id_connected_loans));
                connected = true;
            }
        }

        if(connected){
            setTimeout(()=>{
                setLoading(false);
                setUpdateKey(Math.random());
            },1);
            return;
        }
        await getLoans(count+1);
    }

    const getSpinwheelServicers = useCallback(async () => {
        const response = await axios.get('/spinwheel/get-spinwheel-servicers', { headers:{'Auth-token': authToken}}).catch(e=>{
            console.log(e);
        });

        setSpinwheelServicers(response?.data?.spinwheelServicers || []);
    }, []);

    function mapLoans (loans){
        return loans.map(loan=>{
            const servicer = loan.displayName || loan.servicerAliasName;
            loan.logo = loan.logoUrl;
            loan.name = (loan.loanName || servicer) + ( loan.outstandingBalance?' ($' + loan.outstandingBalance + ')' : '');
            loan.accountName = loan.name;
            loan.account_name = loan.name;
            loan.institution_name = loan.servicerAliasName;
            loan.account_type_token = 'student';
            loan.savings_account_verified = true;
            loan.account_id = 'new-'+uniqid("",true);
            return loan;
        })
    }

    const submit = () =>{
        if(!selectedAccount){
            closeHandler();
        }
        let newAccount = {...selectedAccount};

        if(requireAccountNumber && !accountNumberUpdate) {
            alert('Account Number Required.')
            return;
        }

        delete newAccount.account_id;
        closeHandler(newAccount, requireAccountNumber ? accountNumberUpdate : null);
    };

    const requireAccountNumber = useMemo(() => {
        if(selectedAccount && selectedAccount?.capabilities.payments.billPayment.availability !== 'SUPPORTED') {
            return true;
        }

        return false;
    }, [selectedAccount]);

    const spinwheelServicer = useMemo(() => {
        if(!requireAccountNumber || !selectedAccount) {
            return null;
        }

        const {servicerAliasName} = selectedAccount;
        let foundServicer = null;
        for (let servicer of spinwheelServicers) {
            if(servicerAliasName.includes(servicer.spinwheel_servicer_name)) {
                //Note that certain servicers have similar name. We allow ones later in the list to overwrite earlier ones as they seem more specific.
                foundServicer = {...servicer};
            }
        }

        return foundServicer;

    }, [spinwheelServicers, selectedAccount,requireAccountNumber]);

    

    return (
        <Popup bodyClass={'register-accounts ' + (loading? 'placeholder-holder': '')} >
            <div className={'popup-content register-account-screen'}>
                <h1>Accounts available to connect.</h1>
                {loading &&
                    <span>Loading...</span>
                }
                {(!loading && (directLoans.length>0 || idConnectLoans.length>0)) &&
					<span style={{marginBottom:20}}>Select Your Account</span>
				}
				{(!loading && (directLoans.length<=0 && idConnectLoans.length<=0)) &&
					<>
					<span>No compatible accounts available from this institution</span>
					</>
				}

                {directLoans.length>0 && !loading &&
                <div className={'loan-select-box active'} >
                    <AccountSelector options={directLoans} changeHandler={selectAccount} updateKey={updateKey+'direct'}/>
                </div>
                }
                {idConnectLoans.length>0 && !loading &&
                <div className={'loan-select-box active'} >
                    <AccountSelector options={idConnectLoans} changeHandler={selectAccount} updateKey={updateKey+'id'} />
                </div>
                }

                {loading &&
                    <span className={'loan-option-action'} >
                        Your loans may take a few minutes to populate, please don't close out of the screen.
                        <br/>
                        If your loans don't appear after that, please refresh your screen.
                    </span>
                }
                {(selectedAccount?.liabilityProfile?.accountNumber) &&
                    <span className={'loan-option-action'} >
                        <b>Reported Account Number:</b> {selectedAccount.liabilityProfile.accountNumber}
                    </span>
                }
                {
                    requireAccountNumber &&
                    <label className={'update-account-number-wrapper'}>
                        <span className={'loan-option-action'} >We can't confirm your account number. Please enter the account number your servicer has given you to facilitate Bill Pay payments to your student loan.{spinwheelServicer ? ' Follow the format instructions provided.' : '.'}</span>
                        {
                            spinwheelServicer ?
                                <div className={'format-instructions'}>
                                    <b>Format Instructions:</b>
                                    <span dangerouslySetInnerHTML={{'__html': nl2br(spinwheelServicer.spinwheel_servicer_mask_instructions)}}/></div>
                                : null
                        }
                        <input
                            type="text"
                            value={accountNumberUpdate}
                            onChange={e => {
                                setAccountNumberUpdate(e.target.value);
                            }}
                            placeholder="Account Number"
                        />
                    </label>
                }

				{(!loading) &&
                    <>
                        {((typeof triggerBankLogin === "function") && !refreshTried) &&
                            <span className={'loan-option-action'} >Don't see your account?<br/> <a href={'#'} onClick={e => {
                                e.preventDefault();
                                setLoading(true);
                                getLoans(0, true);
                            }}>Try to refresh</a> </span>
                        }
                        <span className={'loan-option-action'} >Having trouble? <br/> <a href={'#'} onClick={e => {
                            e.preventDefault();
                            helpHandler()
                        }}>Contact us for help</a> </span>
                        {(directLoans.length>0 || idConnectLoans.length>0) &&
                            <button onClick={submit} className={'simple-rounded-button small fixed green'}><span>Connect Selected Loan</span></button>
                        }
                    </>
                }

				<span onClick={()=>{closeHandler(null)}} className={'clickable standard-link green'}>Close</span>
            </div>
        </Popup>
    )
}
