import React, {useState} from 'react'
import usePollAlerts from '../../hooks/usePollAlerts';
import {useStoreActions} from "easy-peasy"; 

export default function Alerts() {

    const [alerts,lastId] = usePollAlerts();
    const [lastSeenId,setLastSeenId] = useState(-1);
    const setShowModal = useStoreActions(actions=>actions.userAlerts.setShowModal);
    if(!alerts || ( Array.isArray(alerts) && alerts.length===0)){
        return null;
    }

    return (
        <div className='alerts-item'>
            <div className='alerts-icon' onClick={()=>{setShowModal(true);setLastSeenId(lastId)}}>
                <img src='/images/bell.svg' className={(lastId>lastSeenId?'animate':'')} alt='alerts' />
            </div>
            <span>
                {alerts.length}
            </span>
        </div>
    )
}