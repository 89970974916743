import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import axios from 'axios';
import {getHttpErrorMessage} from "../../_helpers";
import NumberFormat from "react-number-format";
import FileInput from "../fileInput";
export default function LoanAccountPopup({sucessHandler,closeHandler,authToken, organization, user})

{
	const axiosConfig = {headers:{'Auth-token': authToken}};
	const [selectedVendor,setSelectedVendor] = useState(null)
	const [vendors,setVendors] = useState([]);
	const [updateToken,setUpdateToken] = useState(null);
	const [accountNumber,setAccountNumber] = useState('');
	const [accountNumberConfirmation,setAccountNumberConfirmation] = useState('');
	const [errorFields,setErrorFields] = useState([]);
	const [message,setMessage] = useState('');
	const [accountName,setAccountName] = useState('');
	const [bank,setBank] = useState('')
	const [institutionData,setInstitutionData] = useState(null)
	const [guarantor,setGuarantor] = useState('');
	const [paymentReferenceNumber,setPaymentReferenceNumber] = useState('');
	const [statement,setStatement] = useState(null);

	const updateFile = (type,value) =>{
		setStatement(value);
	};

	const updateSelectedVendor = (vendorId) =>{
		if(!vendorId){
			setSelectedVendor(null);
			return;
		}
		if(vendorId == -1){
			setSelectedVendor({loan_account_vendor_id:-1});
			return;
		}
		setBank(vendors.find(vendor=>vendor.loan_account_vendor_id === vendorId)['loan_account_vendor_title']);
		setSelectedVendor(vendors.find(vendor=>vendor.loan_account_vendor_id === vendorId));
	};

	const checkAccountRequirements = errors =>{
		if(accountNumberConfirmation !== accountNumber){
			errors.push('accountNumberConfirmation');
			setMessage('Account Numbers Must Match.');
		}
		if(accountNumberConfirmation.trim().length<=0){
			errors.push('accountNumberConfirmation');
			setMessage('Confirm Account Number.');
		}
		if(accountNumber.trim().length<=0){
			errors.push('accountNumber');
			setMessage('Account Number Required.');
		}
	};

	const hasErrors = () =>{
		let errors = [];

		if(accountName.trim().length<=0){
			errors.push('accountName');
			setMessage('Account Name required.');
		}
		if(bank.trim().length<=0 && (!selectedVendor || selectedVendor.loan_account_vendor_id<0)){
			errors.push('bank');
			setMessage('Institution Name required.');
		}

		checkAccountRequirements(errors);

		setErrorFields(errors);
		return errors.length>0;
	};

	const saveHandler = () =>{
		if(hasErrors()){
			return;
		}
		let vendorId = (!selectedVendor || selectedVendor.loan_account_vendor_id <0)?null:selectedVendor.loan_account_vendor_id;
		let postData = {
			account: {
				loan_account_placeholder_vendor_name:bank,
				loan_account_title:accountName,
				loan_account_number: accountNumber,
				account_type_token:'student',
				loan_account_reference_number:paymentReferenceNumber,
				loan_account_guarantor:guarantor,
				account_statement:statement
			}
		};
		
		postData.account.loan_account_vendor_id = vendorId;
		
		let config = {};
		if(authToken){
			config.headers = {'Auth-token': authToken}
		}
		axios.post('/loan-vendors/'+organization['organization_id']+'/account',postData, config).then(response=>{
			sucessHandler(response);
		}).catch(e=>{
			setMessage(getHttpErrorMessage(e));
		});
	};

	useEffect(()=>{

		let config = {};
		if (authToken) {
			config.headers = {'Auth-token': authToken}
		}
		axios.get('/loan-vendors/' + organization['organization_id'], config).then(response => {
			setVendors(response.data.vendors);
		}).catch(e => {
		})
	
	},[])

	return (
		<Popup closeHandler={closeHandler}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Add Your Student Loan</h2>
				</div>
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list'}>
						<label>
							Financial Institution:
							
							<select className={selectedVendor ? 'filled ' : ' '}
							        value={selectedVendor ? selectedVendor.account_vendor_id : ''} onChange={(e) => {
								updateSelectedVendor(e.target.value)
							}}>
								<option value={''}>Select a Financial Institution</option>
								{vendors.map(vendor =>
									<option style={{color: '#000'}} key={vendor.loan_account_vendor_id}
									        value={vendor.loan_account_vendor_id}>{vendor.loan_account_vendor_title}</option>
								)}
								<option value={''}>Other</option>
							</select>
							
						</label>
						{(!selectedVendor || selectedVendor.loan_account_vendor_id<0) &&
						<label>
							Institution Name:
							<input className={(errorFields.includes('bank')?'invalid':'')} value={bank} onChange={(e)=>{setBank(e.target.value)}}/>
						</label>
						}
						<label>
							Account Name:
							<input className={(errorFields.includes('accountName')?'invalid':'')} value={accountName} onChange={(e)=>{setAccountName(e.target.value)}}/>
						</label>
						<label className={'tooltip-container'}>
							Guarantor Name
								<span className={'help blue'}/>
								<span className={'tooltip'}>
									This may be optional for your account, but can cause payment delays if not filled in correctly. Ask your loan provider if you are not sure what to enter here.
								</span>:


							<input className={(errorFields.includes('guarantor')?'invalid':'')} value={guarantor} onChange={(e)=>{setGuarantor(e.target.value)}}/>
						</label>
						<label className={'tooltip-container'}>
							Payment Reference Number
							<span className={'help blue'}/>
							<span className={'tooltip'}>
									This may be optional for your account, but can cause payment delays if not filled in correctly. Ask your loan provider if you are not sure what to enter here.
								</span>:
							<input className={(errorFields.includes('paymentReferenceNumber')?'invalid':'')} value={paymentReferenceNumber} onChange={(e)=>{setPaymentReferenceNumber(e.target.value)}}/>
						</label>
						< label className={'full-row'}>
							Account Number:
							<input className={(errorFields.includes('accountNumber')?'invalid':'')} value={accountNumber} onChange={(e)=>{setAccountNumber(e.target.value)}}/>
						</label>

						<label className={'full-row'}>
							Confirm Account Number:
							<input className={(errorFields.includes('accountNumberConfirmation')?'invalid':'')} value={accountNumberConfirmation} onChange={(e)=>{setAccountNumberConfirmation(e.target.value)}}/>
						</label>
						<FileInput label={'Recent Statement:'}
								   descriptionsTitle={'Why we need this'}
								   axiosConfig={axiosConfig}
								   optional={selectedVendor && selectedVendor.account_vendor_is_confirmed === 'yes'}
								   changeHandler={updateFile}
								   descriptions={[{document_description_id:1,document_description_description: 'Having a copy of your most recent statement can help ensure your account is linked correctly.'}]}
								   fileKey={'recentStatement'}
								   fullRow={true}
								   uploadUrl={'/organizations/'+organization['organization_id']+'/users/'+user['user_id']+'/upload-statement'}
								   error={Array.isArray(errorFields) && errorFields.includes('recentStatement')}

						/>
						<div className={'save-row full-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{saveHandler()}}><span>Add Account</span></button>
							{message && <p className={'popup-form-error'}>{message}</p>}
						</div>
					</div>
				</div>
			</div>
		</Popup>
	)
}
