import React, {useState, useEffect, useRef} from 'react';
import {useStoreActions} from "easy-peasy";
import axios from 'axios';
import NumberFormat from "react-number-format";
import {validateEmailAddress, validateNumberFormat, validateWebsiteForForte} from "../../_helpers";
import PatriotActDisclaimerPopup from "../popups/patriotActDisclaimerPopup";
import InfoPopup from "../popups/infoPopup";
import CipExemptionInfoPopup from "../popups/cipExemptionInfoPopup";
import useFormOptions from '../../hooks/useFormOptions';

export default function CompanyInfoSlide({nextHandler,backHandler,organization,authToken,savedCompanyInfo,savedOwnerInfo,updateOrganization}){

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const lastOwnerAnchor = useRef();
	const [showDisclaimerPopup,setShowDisclaimerPopup] = useState(false);
	const [showCipInfoPopup,setShowCipInfoPopup] = useState(false);
	const [ownerExempt,setOwnerExempt] = useState(organization.hasOwnProperty('organization_cip_exempt') && organization['organization_cip_exempt'] === 'yes');
	const [updateKey,setUpdateKey] = useState(Math.random);
	const formOptions = useFormOptions({authToken});
	const [companyInfo,setCompanyInfo] = useState(
		savedCompanyInfo || {
			organization_billing_legal_name:{label:'Legal Name',value:organization.organization_title || ''},
			organization_billing_tax_number:{label:'Tax ID Number (EIN)',value:'', type:'number',format:'##-#######',mask:'_'},
			organization_customer_service_number:{label:'Client Contact Number',value:organization.contact_phone || '',type:'number',format:'(###) ###-####',mask:'_'},
			organization_website:{label:'Website',value:organization.organization_website || '',placeholder:'www.website.com'},
			address_street_1:{label:'Address',value:organization.address_street_1 || ''},
			address_city:{label:'City',value:organization.address_city || ''},
			state_id:{label:'State',value:organization.state_id || '',optionsKey:'states'},
			address_zip:{label:'Postal Code',value:organization.address_zip || ''},
			country_id:{label:'Country',value:organization.country_id || '236',optionsKey:'countries'},
		}
	);
	const [owners,setOwners] = useState(
		 [{
			contact_first_name:{label:'First Name',value:''},
			contact_last_name:{label:'Last Name',value:''},
			organization_owner_title:{label:'Title',value:'', optionsKey:'owner_titles'},
			address_street_1:{label:'Home Address',value:''},
			address_city:{label:'City',value:''},
			state_id:{label:'State',value:'', optionsKey:'states'},
			address_zip:{label:'Postal Code',value:''},
			country_id:{label:'Country',value:'236',optionsKey:'countries'},
			citizenship_type_id:{label:'Citizenship',value:'236',optionsKey:'citizenship_types'},
			contact_email:{label:'Email Address',value:''},
			contact_phone:{label:'Mobile Phone',value:'',type:'number',format:'(###) ###-####',mask:'_'},
			organization_owner_ssn:{label:'SSN (Last 4 digits)',value:'',type:'number',format:'####',mask:'_'},
			organization_owner_full_ssn:{label:'Full SSN',value:'',type:'number',format:'###-##-####',mask:'_'},
			organization_owner_dob:{label:'Date of Birth',value:'',type:'number',format:'##/##/####',mask:['M','m','D','d','Y','e','a','r']},
			organization_owner_percentage:{label:'Ownership Percentage',value:'',type:'number',suffix:'%',message:'*Enter 100 if no one owns more than 25% of the company.'}
		}]
	);

	const percentageNotNeededTypes = ['government','sole_proprietorship','publicly_held_corporation','c_corporation'];
	const exemptedFields = ['organization_owner_ssn',
		'organization_owner_full_ssn',
		'organization_owner_dob',
		'organization_owner_percentage',
		'citizenship_type_id',
		'address_street_1',
		'address_city',
		'state_id',
		'address_zip',
		'country_id'
		];
	const updateCompanyInfo = (key,value) =>{
		if(companyInfo[key]) {
			companyInfo[key].value = value;
			companyInfo[key].error = !value;
			setCompanyInfo(companyInfo);
			setUpdateKey(Math.random())
		}
	};
	const updateOwnerInfo = (index,key,value) =>{
		if(owners[index] && owners[index][key]) {
			owners[index][key].value = value;
			setOwners(owners);
			setUpdateKey(Math.random())
		}
	};

	const mapFormForPost = (formData) =>{
		let data = {};
		Object.entries(formData).forEach((entry)=>{
			if(!entry[1]){
				return;
			}
			data[entry[0]] = entry[1].value;
		});
		return data;
	};

	const validateFormObject = (formObject,missingFields) =>{
		Object.entries(formObject).forEach((entry)=>{
			if(!entry[1]){
				return;
			}
			if(ownerExempt && exemptedFields.includes(entry[0])){
				return;
			}
			if(!entry[1].value){
				formObject[entry[0]].error = true;
				missingFields.push(entry[0]);
			}else if(entry[1].type && entry[1].type === 'number' && entry[1].format){
				if(!validateNumberFormat(entry[1].value,entry[1].format, false)){
					formObject[entry[0]].error = true;
					missingFields.push(entry[0]);
				}else{
					formObject[entry[0]].error = false;
				}
			}else if(entry[0].indexOf('email')>-1){
					if(!validateEmailAddress(entry[1].value)){
						formObject[entry[0]].error = true;
						missingFields.push(entry[0]);
					}else{
						formObject[entry[0]].error = false;
					}
			}else if(entry[0].indexOf('website')>-1){
				entry[1].value = entry[1].value.replace(/(^\w+:|^)\/\//, '');
				if(!validateWebsiteForForte(entry[1].value)){
					if(validateWebsiteForForte('www.'+entry[1].value)){
						entry[1].value = 'www.'+entry[1].value;
						formObject[entry[0]].error = false;
					}else {
						formObject[entry[0]].error = true;
						missingFields.push(entry[0]);
					}
				}else{
					formObject[entry[0]].error = false;
				}
			}else{
				formObject[entry[0]].error = false;
			}
		});
	};

	const submitHandler = () =>{
		let missingFields = [];
		owners.forEach(ownerInfo=>{validateFormObject(ownerInfo,missingFields);})
		setOwners(owners);
		validateFormObject(companyInfo,missingFields);
		setCompanyInfo(companyInfo);
		setUpdateKey(Math.random());
		if(missingFields.length>0) {
			showMessage({message: 'All Fields are Required.', type: 'error'});
			return;
		}

		let postData = {
			owners:owners.map(mapFormForPost),
			billing:mapFormForPost(companyInfo),
			cip_exempt:ownerExempt?'yes':'no'
		};

		axios.post('/organizations/'+organization.organization_id+'/owner-billing',postData,{headers:{'Auth-token': authToken}}).then(response=>{
			if(response.data.organization) {
				updateOrganization(response.data.organization);
			}
			nextHandler();
		}).catch(e=>{})
	};

	const updateCompanyInfoForBusinessType = (companyInfo) =>{
		return; // this isn't used right now
		let accountTypes = organization.organization_account_types.map(type=>type.account_type_token);
		Object.entries(companyInfo).forEach(entry=>{

			if(!accountTypes.includes('student')){
				//Forte requirements only

			}else if(accountTypes.length === 1 && accountTypes.includes('student')){
				//Rightfoot requirements only
				// switch(entry[0]){
				// 	case 'organization_billing_dba_name':
				// 	case 'organization_customer_service_number':
				// 	case 'organization_website':
				// 		companyInfo[entry[0]] = null;
				// 		break;
				// 	default:
				// 		break;
				// }
			}else{
				//both requirements
			}
		});
	}

	const updateOwnerInfoForBusinessType = (ownerInfo) =>{
		let accountTypes = organization.organization_account_types.map(type=>type.account_type_token);
		Object.entries(ownerInfo).forEach(entry=>{
			if(percentageNotNeededTypes.includes(organization.business_legal_structure_token) && entry[0] === 'organization_owner_title'){
				ownerInfo[entry[0]].value = 'ceo'
			}else if(percentageNotNeededTypes.includes(organization.business_legal_structure_token) && entry[0] === 'organization_owner_percentage'){
				ownerInfo[entry[0]] = null;
			}
			if(!accountTypes.includes('student')){
				//Forte requirements only
				if(entry[0] === 'organization_owner_full_ssn'){
					ownerInfo[entry[0]] = null;
				}
			}else if(accountTypes.length === 1 && accountTypes.includes('student')){
				//Rightfoot requirements only
				switch(entry[0]){
					// case 'organization_owner_percentage':
					case 'organization_owner_ssn':
					// case 'contact_phone':
					// case 'citizenship_type_id':
						ownerInfo[entry[0]] = null;
						break;
					default:
						break;
				}
			}if(accountTypes.includes('student')){
				if(entry[0] === 'organization_owner_ssn'){
					ownerInfo[entry[0]] = null;
				}
			}
		});
	};

	const addOwner = () =>{
		let newOwner = JSON.parse(JSON.stringify(owners[0]));
		Object.entries(newOwner).forEach((entry)=>{
			if(newOwner[entry[0]]) {
				newOwner[entry[0]].value = '';
			}
		});
		setOwners([...owners,newOwner]);
		setUpdateKey(Math.random());
		setTimeout(()=>{
			window.scrollTo(0, lastOwnerAnchor.current.offsetTop)
		},1)
	};

	const removeOwner = (index) =>{
		setOwners(owners.filter((owner,i)=>index !== i));
		setUpdateKey(Math.random());
	};

	useEffect(()=>{
			const cancelToken = axios.CancelToken.source();

		axios.get('/organizations/'+organization.organization_id+'/owner', {cancelToken:cancelToken.token,headers:{'Auth-token': authToken}}).then(response=>{
			if(response.data.owners){
				let newOwners = []
				if(response.data.owners.length>0) {
					newOwners = response.data.owners.map((ownerInfo) => {
						let existingInfo = JSON.parse(JSON.stringify(owners[0]));
						Object.entries(ownerInfo).forEach((entry) => {
							if (existingInfo[entry[0]] && entry[1]) {
								existingInfo[entry[0]].value = entry[1];
							}
						});
						updateOwnerInfoForBusinessType(existingInfo);

						return existingInfo;
					});
				}else{
					newOwners = owners.map(ownerInfo=> {
						updateOwnerInfoForBusinessType(ownerInfo);
						return ownerInfo;
					});
				}
				setOwners(newOwners);
			}else{
				setOwners(owners.map(ownerInfo=> updateOwnerInfoForBusinessType(ownerInfo)))
			}

			setUpdateKey(Math.random());
		}).catch(e=>{});

		axios.get('/organizations/'+organization.organization_id+'/billing', {cancelToken:cancelToken.token,headers:{'Auth-token': authToken}}).then(response=>{
			if(response.data.billing){
				Object.entries(response.data.billing).forEach((entry)=>{
					if(companyInfo[entry[0]] && entry[1]) {
						companyInfo[entry[0]].value = entry[1];
					}
				});

			}
			if(response.data.ein){
				companyInfo['organization_billing_tax_number'].value = response.data.ein;
			}
			updateCompanyInfoForBusinessType(companyInfo);
			setCompanyInfo(companyInfo);
			setUpdateKey(Math.random());
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[]);

	return (
		<>
			{showDisclaimerPopup && <PatriotActDisclaimerPopup closeHandler={()=>{setShowDisclaimerPopup(false)} } isExempt={ownerExempt} /> }
			{showCipInfoPopup && <CipExemptionInfoPopup closeHandler={()=>{setShowCipInfoPopup(false)} } /> }
		<div className={'slide welcome-slide form-slide'}>
			<div className={'title'}>
				<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Company Information</h2>
			</div>
			<div className={'form-container'}>
				<div className={'form-title form-inner'}>
					Applicant Organization
				</div>
				<div className={'form-inner field-list'}>
					{companyInfo && Object.entries(companyInfo).map((entry,index)=>{
						if (!entry[1]){
							return null;
						}
						if(entry[1].optionsKey && Object.hasOwnProperty.call(formOptions,entry[1].optionsKey)){
							return (
								<label key={'ci'+index}>
									{entry[1].label}
									<select className={(entry[1].value?'filled ':' ') + (entry[1].error?'invalid':'')} value={entry[1].value} onChange={(e)=>{updateCompanyInfo(entry[0],e.target.value)}}>
										<option  value={''}>Select {entry[1].label}</option>
										{formOptions[entry[1].optionsKey] && formOptions[entry[1].optionsKey].map((option,index)=>(
											<option  style={{color:'#000'}} key={option.value+'-'+index} value={option.value}>{option.label}</option>
										))}
									</select>
								</label>
							)
						}else if(entry[1].type && entry[1].type==='number'){
								return (
									<label key={'ci'+index}>
										{entry[1].label}
										<NumberFormat
											className={(entry[1].error?'invalid':'')}
											placeholder={entry[1].label}
											format={entry[1].format}
											mask={entry[1].mask}
											decimalScale={entry[1].decimals || 0}
											prefix={entry[1].prefix || ''}
											suffix={entry[1].suffix || ''}
											value={entry[1].value}
											onValueChange={(values) => {
												const {value} = values;
												if(value) {
													updateCompanyInfo(entry[0], value);
												}
											}} />
									</label>
								)
							}
							return(
								<label key={'ci'+index}>
									{entry[1].label}
									<input name={'billing_'+entry[0]} className={(entry[1].error?'invalid':'')} type={'text'} value={entry[1].value} placeholder={entry[1].label} onChange={(e)=>{updateCompanyInfo(entry[0],e.target.value)}}/>
								</label>
							)
					})}
				</div>
			</div>
			{owners.map((ownerInfo,index)=>{
				if(!ownerInfo){
					return null;
				}
				return (
			<div key={index} className={'form-container'}>
				{index===owners.length-1 && <a ref={lastOwnerAnchor} />}
						<div className={'form-title form-inner wrap'}>
							{(organization && !percentageNotNeededTypes.includes(organization.business_legal_structure_token)) &&
							<div>
								Authorized  <span>Signer {index > 0 ? index + 1 : ''}<span onClick={() => {
								setShowDisclaimerPopup(true)
								}} className={'clickable'}><img src={'/images/question-blue.svg'} alt={''}/></span></span>
							</div>
							}
							{(organization && percentageNotNeededTypes.includes(organization.business_legal_structure_token)) &&
							<div>
								Authorized <span>Signer <span onClick={() => {
								setShowDisclaimerPopup(true)
							}} className={'clickable'}><img src={'/images/question-blue.svg'} alt={''}/></span></span>
							</div>
							}
							{(index === 0 && ownerExempt) &&
							<div className={'check-holder'} >
								This Organization is (CIP) <span>exempt <span onClick={() => {
								setShowCipInfoPopup(true)
							}} className={'clickable'}><img src={'/images/question-blue.svg'} alt={''}/></span></span>
							</div>
							}
							{index > 0 &&
								<button className={'remove-button'} onClick={() => {
									removeOwner(index)
								}}>Remove</button>
							}
						</div>
						<div className={'form-inner field-list'}>

						{Object.entries(ownerInfo).map((entry,keyIndex)=>{
								if (!entry[1]){
									return null;
								}
								if(ownerExempt && exemptedFields.includes(entry[0])){
									return null;
								}
								if(entry[1].optionsKey){
									return (
										<label key={'ow'+index+keyIndex}>
											{entry[1].label}
											<select className={(entry[1].value?'filled ':' ') + (entry[1].error?'invalid':'')} value={entry[1].value} onChange={(e)=>{updateOwnerInfo(index,entry[0],e.target.value)}}>
												<option value={''}>Select {entry[1].label}</option>
												{formOptions[entry[1].optionsKey] && formOptions[entry[1].optionsKey].map((option,index)=>(
													<option  style={{color:'#000'}} key={option.value+'-'+index} value={option.value}>{option.label}</option>
												))}
											</select>
											{entry[1].message && <span className={'field-message'}>{entry[1].message}</span> }
										</label>
									)
								}else if(entry[1].type && entry[1].type==='number'){
									return (
										<label key={'ow'+index+keyIndex}>
											{entry[1].label}
											<NumberFormat
												className={(entry[1].error?'invalid':'')}
												placeholder={entry[1].label}
												format={entry[1].format}
												mask={entry[1].mask}
												decimalScale={entry[1].decimals || 0}
												prefix={entry[1].prefix || ''}
												suffix={entry[1].suffix || ''}
												value={entry[1].value}
												onValueChange={(values) => {
													const {value} = values;
													updateOwnerInfo(index, entry[0], value);

												}} />
												{entry[1].message && <span className={'field-message'}>{entry[1].message}</span> }
										</label>
									)
								}else{
									return(
										<label key={'ow'+index+keyIndex}>
											{entry[1].label}
											<input name={'owner_'+entry[0]} className={(entry[1].error?'invalid':'')} type={'text'} value={entry[1].value} placeholder={entry[1].label} onChange={(e)=>{updateOwnerInfo(index,entry[0],e.target.value)}}/>
											{entry[1].message && <span className={'field-message'}>{entry[1].message}</span> }
										</label>
									)
								}
							})}
				</div>



			</div>
				)
			})}
			{(organization && !percentageNotNeededTypes.includes(organization.business_legal_structure_token)) &&
			<div className={'add-owner'}>
				<button onClick={() => {
					addOwner();
				}} className={'simple-rounded-button small green fixed'}><span>+ Add Authorized Signer</span></button>
				<span>
					(Must have at least 25% ownership)
				</span>
			</div>
			}
			<div className={'button-holder'}>
				<button onClick={()=>{backHandler()}} className={'simple-rounded-button small fixed green faded'}><span>Back</span></button>
				<button onClick={()=>{submitHandler()}} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
			</div>
		</div>
		</>
	)
}
