import React from 'react';
import {Link} from 'react-router-dom'
import Popup from "./popup";
import { useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
export default function HasInvalidSpinwheelPopup({closeHandler, organizationId})
{
    const sawBefore = useStoreState(state=>state.user.sawSpinwheelInvalidPopup);
    const resetSpinwheelInvalid = useStoreActions(actions=>actions.user.resetSpinwheelInvalid);

    const optOut = () =>{
        if(window.confirm('Payments can not be made to invalid accounts. Are you sure you want to opt out of these warnings?')){
            axios.post('/spinwheel/opt-out-invalid',{"organization_id":organizationId}).catch(err=>{})
            resetSpinwheelInvalid();
            closeHandler(true);
        }
    }

    return (
        <Popup closeHandler={closeHandler} bodyClass={'register-accounts'}>
            <div className={'popup-content register-account-screen manual-loan-message'}>
                <span>One or more of your student loan connections are no longer valid.</span>
                <span>Go to the <Link to={'/employee/accounts'} onClick={e => {
                    closeHandler(true)
                }}>'Register Account'</Link> tab and re-register your student loan accounts to continue receiving payments.</span>
                {sawBefore &&
                    <>
                        <br/>
                        <span>Don't want to see this message again? <button onClick={optOut}>Acknowledge invalid accounts and opt out of warnings.</button></span>
                    </>
                }
            </div>
        </Popup>
    )
}
