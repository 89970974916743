import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import { ownerTitleOptions } from '../_constants';

export default function useFormOptions({authToken}){
    const [formOptions, setFormOptions] = useState({})

    useEffect(()=>{
        const cancelToken = axios.CancelToken.source();
        let config = {cancelToken:cancelToken.token};
        if(authToken){
            config.headers = {'Auth-token': authToken};
        }
        axios.get('/form-options',config).then(response=>{
            let businessTypes = remapFormOptions(response.data.business_types);
            let bankTypes = remapFormOptions(response.data.bank_account_types);
            let citizenshipTypes = remapFormOptions(response.data.countries);
            let countries = remapFormOptions(response.data.countries);
            let states = remapFormOptions(response.data.states);
            let legalStructures = remapFormOptions(response.data.business_legal_structure_types);
            setFormOptions({
                business_types : businessTypes,
                bank_account_types:bankTypes,
                citizenship_types:citizenshipTypes,
                countries:countries,
                states:states,
                business_legal_structure_types:legalStructures,
                owner_titles:ownerTitleOptions
            });
        }).catch(e=>{});

        return ()=>{
            cancelToken.cancel();
        }
    },[])

    const remapFormOptions = (options) =>{
		return options.map((option)=>{
			let labelKey = '';
			let valueKey = '';
			for(const propKey in option){
				if(propKey.indexOf('_title')>-1 || propKey.indexOf('_name')>-1){
					labelKey = propKey;
				}else if(propKey.indexOf('_id')>-1){
					valueKey = propKey;
				}
			}
			option.label = option[labelKey];
			option.value = option[valueKey];
			return option;
		})
	};

    return formOptions;
}