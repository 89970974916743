import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom'
import MainMenu from "./mainMenu";
import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock';
import {useStoreActions, useStoreState} from "easy-peasy";
import ContactPopup from "../popups/contactPopup";
import {ADMIN_TYPE, ADMIN_USER_TYPES, EMPLOYER_CONTACT_TYPE, tabletMedWidth} from "../../_constants";
import usePollAlerts from '../../hooks/usePollAlerts';
import Alerts from './alerts';

export default function Header({layout}){
    const history = useHistory();
    const [mobileMenuOpen,setMobileMenuOpen] =  useState(false);
    const mobileRef = React.createRef();
    const [accountMenuOpen,setAccountMenuOpen] = useState(false);
    const setAuthToken = useStoreActions(actions => actions.user.updateToken);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const availableOrganizations = useStoreState(state=>state.user.organizations);

    const [showAlertModal,setShowAlertModal] = useState(false);
    
    const showContactForm = useStoreState(state=>state.modals.showContact);
    const setShowContactForm = useStoreActions(actions=>actions.modals.setShowContact);

    const handleWidthChange = () =>{
        if(window.innerWidth>=tabletMedWidth){
            setMobileMenuOpen(false);
        }
    };
    useEffect(()=>{
        if(mobileRef.current) {
            if (mobileMenuOpen) {
                disableBodyScroll(mobileRef.current.targetElement);
            } else {
                enableBodyScroll(mobileRef.current.targetElement);
            }
        }else{
            clearAllBodyScrollLocks()
        }

    },[mobileMenuOpen]);

    useEffect(()=>{
        window.addEventListener('resize',handleWidthChange);

        return ()=>{
            window.removeEventListener('resize',handleWidthChange);
        }
    },[])

    return (
        <header className={'full-width ' + (mobileMenuOpen?'mobile-open':'')}>
            <div className={'max-width-container header-holder'}>
                <img className={'header-logo'} src={'/images/logo.svg'} />
                <div className={'desktop-nav-holder'}>
                    <Alerts />
                    <MainMenu clickHandler={setMobileMenuOpen}/>
                    {accountMenuOpen && <div className={'click-catcher'} onClick={()=>{setAccountMenuOpen(false)}} />}
                    <div onClick={()=>{setAccountMenuOpen(!accountMenuOpen)}} className={'account-button clickable'}>
                        {activeOrganization &&
                            <>
                            <img src={activeOrganization.organization_logo_url} alt={''} />

                            </>
                        }<span className={'mdi mdi-dots-vertical'} />
                        <div className={'org-name-holder'}>
                            {activeOrganization && <span className={'org-name'}>{activeOrganization.organization_view_title || activeOrganization.organization_title}</span>}
                            {(activeOrganization && (ADMIN_USER_TYPES.includes(activeOrganization.role_token) )) &&
                                <span className={'plan-name'}>{activeOrganization.plan_title}</span>
                            }
                        </div>
                        {accountMenuOpen &&
                        <>
                            <div className={'option-list single'}>
                                <Link to={'/account'}>Account Settings</Link>
                                {(activeOrganization && (activeOrganization.role_token === ADMIN_TYPE || activeOrganization.role_token === EMPLOYER_CONTACT_TYPE)) &&
                                    <Link to={'/company-account'}>Company Account</Link>
                                }
                                {availableOrganizations.filter(role => role.user_organization_role_status === 'active').length > 1 &&
                                    <span onClick={() => {
                                        history.push('/switch')
                                    }}>Switch Accounts <img src={"/images/switch.svg"}/></span>
                                }
                                <span onClick={()=>{setAuthToken()}}>Log Out <img src={"/images/logout.svg"}/></span>
                            </div>

                        </>
                        }
                    </div>
                </div>
                {(layout === 'tabletMed' || layout === 'mobileMax') &&
                    <div style={{display:'flex',gap:8}}>
                    <Alerts />
                        {showContactForm && <ContactPopup />}
                        <div className={'mobile-nav-holder'}>
                            <button
                                onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}
                                className={'mobile-menu-button'}>
                                <img src={'/images/mobile-toggle.svg'}/>
                            </button>
                        </div>
                        <div  className={'mobile-main-menu-holder '+(mobileMenuOpen?'open':'')}>
                            <div className={'mobile-menu-header'}>
                                <img className={'header-logo'} src={'/images/logo-white.svg'} />
                                <button
                                    onClick={() => {setMobileMenuOpen(!mobileMenuOpen)}}
                                    className={'mobile-menu-button'}>
                                    <img src={'/images/mobile-close.svg'}/>
                                </button>
                            </div>
                            <div ref={mobileRef} className={'menu-holder'}>
                                <MainMenu clickHandler={setMobileMenuOpen} withAccountInfo={true}/>

                            </div>
                            <div className={'mobile-menu-footer'}>
                                <div className={'footer full-width'}>
                                    <div className={'max-width-container'}>
                                        <div className={'logo-holder'}>
                                            <img src={'/images/logo-no-text-white.svg'} />
                                            <span>© {new Date().getFullYear()} Thrive, LLC.</span>
                                        </div>
                                        <a href={'#'} onClick={e=>{e.preventDefault();setShowContactForm(true)}}>
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </header>
    )
}
