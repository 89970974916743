import Popup from "./popup";
import React, {useEffect, useState} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import useFormOptions from "../../hooks/useFormOptions";


export default function CompanyInformationPopup({closeHandler}) {

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [updateKey,setUpdateKey] = useState(Math.random);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const formOptions = useFormOptions({});
	const [companyInfo,setCompanyInfo] = useState({
			organization_billing_legal_name:{label:'Legal Name',value:''},
			organization_billing_dba_name:{label:'DBA Name',value:''},
			organization_billing_tax_number:{label:'Tax ID Number',value:''},
			address_street_1:{label:'Address',value:''},
			address_city:{label:'City',value:''},
			state_id:{label:'State',value:'',optionsKey:'states'},
			address_zip:{label:'Postal Code',value:''},
			country_id:{label:'Country',value:'',optionsKey:'countries'},
		}
	);

	const updateCompanyInfo = (key,value) =>{
		companyInfo[key].value = value;
		companyInfo[key].error = !value;
		setCompanyInfo(companyInfo);
		setUpdateKey(Math.random())
	};

	const mapFormForPost = (formData) =>{
		let data = {};
		Object.entries(formData).forEach((entry)=>{
			data[entry[0]] = entry[1].value;
		});
		return data;
	};

	const submitHandler = () =>{
		let missingFields = false;
		Object.entries(companyInfo).forEach((entry)=>{
			if(!entry[1].value){
				companyInfo[entry[0]].error = true;
				missingFields = true;
			}else{
				companyInfo[entry[0]].error = false;
			}
		});
		setCompanyInfo(companyInfo);
		setUpdateKey(Math.random());
		if(missingFields) {
			showMessage({message: 'All Fields are Required.', type: 'error'})
			return;
		}

		let postData = {
			billing:mapFormForPost(companyInfo)
		};

		axios.post('/organizations/'+activeOrganization.organization_id+'/owner-billing',postData).then(response=>{

		}).catch(e=>{})
	};

	useEffect(()=>{
		const cancelToken = axios.CancelToken.source();

		axios.get('/organizations/'+activeOrganization.organization_id+'/billing', {cancelToken:cancelToken.token}).then(response=>{
			if(response.data.billing){
				Object.entries(response.data.billing).forEach((entry)=>{
					if(companyInfo[entry[0]]) {
						companyInfo[entry[0]].value = entry[1];
					}
				});
				setCompanyInfo(companyInfo);
				setUpdateKey(Math.random());
			}
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
		}
	},[]);

	return (
		<Popup closeHandler={closeHandler} tabbed={false}>
			<div className={'large-form popup-content form-slide'}>
				<div className={'title'}>
					<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Company Information</h2>
				</div>
				<div className={'form-container no-border'}>

					<div className={'form-inner field-list'}>
						{Object.entries(companyInfo).map((entry,index)=>{
							if(entry[1].optionsKey && Object.hasOwnProperty.call(formOptions,entry[1].optionsKey)){
								return (
									<label key={'ci'+index}>
										{entry[1].label}
										<select className={(entry[1].value?'filled ':' ') + (entry[1].error?'invalid':'')} value={entry[1].value} onChange={(e)=>{updateCompanyInfo(entry[0],e.target.value)}}>
											<option value={''}>Select {entry[1].label}</option>
											{formOptions[entry[1].optionsKey] && formOptions[entry[1].optionsKey].map((option,index)=>(
												<option style={{color:'#000'}} key={option.value+'-'+index} value={option.value}>{option.label}</option>
											))}
										</select>
									</label>
								)
							}else{
								return(
									<label key={'ci'+index}>
										{entry[1].label}
										<input className={(entry[1].error?'invalid':'')} type={'text'} value={entry[1].value} placeholder={entry[1].label} onChange={(e)=>{updateCompanyInfo(entry[0],e.target.value)}}/>
									</label>
								)
							}
						})}
						<div className={'save-row'}>
							<button  className={'simple-rounded-button blue'} onClick={(e)=>{}}><span>Save Changes</span></button>
						</div>
					</div>

				</div>

			</div>
		</Popup>
	)
}
