import React, {useState,useEffect} from 'react';
import {useStoreActions} from "easy-peasy";
import FileInput from "../fileInput";
import axios from "axios";
import {baseApiUrl} from "../../_constants";

export default function BankDocumentSlide({nextHandler,backHandler,authToken,organization,user}) {

	const axiosConfig = {headers:{'Auth-token': authToken}};
	const uploadUrl = '/organizations/'+organization.organization_id+'/document-upload';
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const setTermsType = useStoreActions(actions=>actions.forte.setTermsType);
	const [updateKey,setUpdateKey] = useState(Math.random());
	const [files,setFiles] = useState(null);
	const [requiredDocs,setRequiredDocs] = useState([]);
	const [auditedFinancials,setAuditedFinancials] = useState(true);
	const [availableFiles,setAvailableFiles] = useState();
	const [ownerDocuments,setOwnerDocuments] = useState([]);

	const accountTypeTitle = {
		merchant:'Merchant Account Requirements',
		government:'Government Account Requirements',
		non_profit:'Non-Profit Account Requirements'
	};

	const updateFile = (type,value) =>{
		setFiles(files.map(f=>{
			if(f.type===type){
				f.value = value;
				f.error = !value
			}
			return f;
		}));
		setUpdateKey(Math.random());
	};

	const updateOwnerFile = (ownerId,value) =>{
		setOwnerDocuments(ownerDocuments.map(file=>{
			if(file.organization_owner_id === ownerId){
				file.value = value;
				file.error = !value
			}
			return file;
		}))
	};

	const submitHandler = () =>{
		let missingFiles = false;

		setFiles(files.map((entry)=>{

			if (!entry.value && entry.required) {
				entry.error = true;
				missingFiles = true;
			} else {
				entry.error = false;
			}

			return entry;
		}));
		setTermsType('forte-terms-no-pg');

		setUpdateKey(Math.random());
		if(missingFiles){
			showMessage({message:'Required Files Missing.',type:'error'});
			return;
		}

		let postData = {
			documents:[
				...files.filter(f=>!!f.value).map(file=>{
				return {
					organization_document_file:file.value,
					organization_document_type:file.type,
					organization_document_type_id: file.type_id
				}
			}),
				...ownerDocuments.map(file=>{
					return {
						organization_document_file:file.value,
						organization_document_type:file.organization_document_type_token,
						organization_owner_id:file.organization_owner_id,
						organization_document_type_id: file.organization_document_type_id
					}
				})
			]
		};
		axios.post('/organizations/'+organization.organization_id+'/documents',postData,{headers:{'Auth-token': authToken}}).then(response=>{
			nextHandler();
		}).catch(e=>{})
	};

	const formatRequiredDocs = (docs)=>{
		let documentSlots = [];
		docs.forEach(doc=> {
			if (doc) {

			documentSlots.push({
				type: doc.organization_document_type_token,
				label: doc.organization_document_type_title,
				type_id:doc.organization_document_type_id,
				descriptions:doc.descriptions,
				value: '',
				required: doc.organization_document_type_token !== 'dba'
			})
		}
		});
		return documentSlots;
	};

	useEffect(()=>{
		if(!files){
			return;
		}
		if(!auditedFinancials && requiredDocs && (requiredDocs.includes('business_financials') || files.filter(entry=>entry.type==='business_financials').length>0) && !requiredDocs.includes('tax_returns')){
			let bfIndex = 0;
			files.forEach((entry,index)=>{if(entry.type==='business_financials'){bfIndex=index; return false;}});
			files.splice(bfIndex+1,0,{
				type:'tax_returns',
				label:availableFiles.tax_returns.label
			});
			setFiles(files);
			setUpdateKey(Math.random());
		}else if(auditedFinancials && !requiredDocs.includes('tax_returns')){
			setFiles(files.filter(entry=>entry.type !== 'tax_returns'));
			setUpdateKey(Math.random());
		}
	},[auditedFinancials,requiredDocs]);

	useEffect(()=>{
		if(!organization.using_student_loans){
			nextHandler();
			return ;
		}
		const cancelToken = axios.CancelToken.source();
		const cancelToken2 = axios.CancelToken.source();

		axios.get('/organizations/'+organization.organization_id+'/required-documents', {cancelToken:cancelToken2.token,headers:{'Auth-token': authToken}}).then(response=>{
			if(response.data.skip_step){
				nextHandler();
				return;
			}
			if(response.data.required_documents){
				setRequiredDocs(response.data.required_documents);
				let requiredOwnerDocuments = response.data.owner_documents;
				setOwnerDocuments(requiredOwnerDocuments);
				let availableFileTypes = response.data.available_types;
				setAvailableFiles(availableFileTypes);
				let formattedDocs = formatRequiredDocs(response.data.required_documents);
				setFiles(formattedDocs);
				setUpdateKey(Math.random());
				axios.get('/organizations/'+organization.organization_id+'/documents', {cancelToken:cancelToken.token,headers:{'Auth-token': authToken}}).then(response=>{
					if(response.data.documents && formattedDocs){
						setFiles(formattedDocs.map(file=>{
							let existingFile = response.data.documents.filter(ef=>ef && file.type===ef['organization_document_type_token']);
							if(existingFile.length>0){
								file.value = existingFile[0]['organization_document_file']
							}
							return file;
						}));
						let ownerType = availableFileTypes.filter(file=>file.organization_document_type_token === 'owner_photo')[0];
						setOwnerDocuments(requiredOwnerDocuments.map(file=>{
							let existingFile = response.data.documents.filter(ef=>ef && file.organization_owner_id===ef['organization_owner_id']);
							if(existingFile.length>0){
								file.value = existingFile[0]['organization_document_file']
							}
							file.organization_document_type_token = ownerType.organization_document_type_token;
							file.organization_document_type_id = ownerType.organization_document_type_id;
							file.descriptions = ownerType.descriptions;
							return file;
						}));
						setUpdateKey(Math.random());
					}
				}).catch(e=>{});
			}
		}).catch(e=>{});

		return ()=>{
			cancelToken.cancel('');
			cancelToken2.cancel('');
		}
	},[]);

	return(
		<div className={'slide welcome-slide form-slide'}>
			<div className={'title'}>
				<h2><img className={'thrive-logo'} src={'/images/thrive-contribution.svg'} alt={'thrive'} />Document Uploads</h2>
				<p>
					Please upload the following documentation so that we can verify your bank account for transfer purposes.
				</p>
			</div>
			<div className={'form-container'}>
				<div className={'sub-title-box form-inner'}>
					<h3>
						{accountTypeTitle[organization.organization_risk_group.replace(/-/g,'_')]}
					</h3>
				</div>
				<div className={'document-list-block'}>
					{files && files.map((entry,index)=>{
						if(entry.type==='business_financials'){
								return (
									<div key={entry.type+index}>
										<div className={'label'}>
											Business Financials*
											<div  className={'document-options-selector'}>
												<div className={'check-holder '} onClick={() => {
													setAuditedFinancials(!auditedFinancials);
												}}>
													Financials are audited
													<span className={'checkbox ' + (auditedFinancials ? 'checked ' : ' ')}/>
												</div>

											</div>
											<FileInput
												axiosConfig={axiosConfig}
												fullRow={true}
												label={entry.label}
												fileKey={entry.type}
												uploadUrl={uploadUrl}
												error={entry.error}
												changeHandler={updateFile}
												existingValue={entry.value}/>
										</div>

									</div>
								)
							}
						if(entry.type==='bank_confirmation'){
							return (
								<div key={entry.type+index}>
									<div className={'label'}>
								<FileInput
									axiosConfig={axiosConfig}
									fullRow={true}
									key={entry.type+index}
									label={entry.label}
									fileKey={entry.type}
									descriptions={entry.descriptions}
									subText={'Upload a copy of a voided check or download account verification form'}
									uploadUrl={uploadUrl}
									error={entry.error}
									optional={!entry.required}
									changeHandler={updateFile}
									existingValue={entry.value} />

										<a style={{display:'block',marginTop:'-20px'}} href={baseApiUrl+'/documents/forte/Bank_Account_Verification_Form.pdf'} target={'_blank'} rel={"noreferrer noopener"} download>Download Bank Confirmation Form</a>
									</div>

								</div>
							)
						}
							return (
								<FileInput
									axiosConfig={axiosConfig}
									fullRow={true}
									key={entry.type+index}
									label={entry.label}
									fileKey={entry.type}
									descriptions={entry.descriptions}
									uploadUrl={uploadUrl}
									error={entry.error}
									optional={!entry.required}
									changeHandler={updateFile}
									existingValue={entry.value} />
							)
						}
					)}
				</div>
			</div>
			{ownerDocuments.length > 0 &&
			<>
				<div className={'title'}>
					<h2>Photo IDs are required for the following owners or representatives:</h2>
				</div>
				<div className={'form-container'}>
					<div className={'document-list-block'}>
						{ownerDocuments.map((document, index) => {
								return (<FileInput
									axiosConfig={axiosConfig}
									fullRow={true}
									key={(document.organization_document_type_token || document.type) + index}
									label={document.display_name}
									descriptions={document.descriptions}
									fileKey={document.organization_owner_id}
									uploadUrl={uploadUrl}
									error={document.error}
									changeHandler={updateOwnerFile}
									existingValue={document.value}/>)
							}
						)}
					</div>
				</div>
			</>
			}
			<div className={'button-holder'}>
				<button onClick={()=>{backHandler()}} className={'simple-rounded-button small fixed green faded'}><span>Back</span></button>
				<button onClick={()=>{submitHandler()}} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
			</div>
		</div>
	)
}
