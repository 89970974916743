import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from "react-router";
import SignupHeader from "../components/signup/signupHeader";
import WelcomeSlide from "../components/signup/employee/welcomeSlide";
import WelcomeSlide2 from "../components/signup/employee/welcomeSlide2";
import ElectContributionSlide from "../components/signup/employee/electContributionsSlide";
import AccountSetupSlide from "../components/signup/accountSetupSlide";
import RegisterAccountsSlide from "../components/signup/employee/registerAccountsSlide";
import FaqPopup from "../components/popups/faqPopup";
import axios from "axios";
import {
	inviteUrl,
	contributionSaveSettingsUrl,
	ADMIN_TYPE,
	EMPLOYEE_TYPE,
	HR_TYPE, EMPLOYEE_TYPE_NO_EVOSHARE, ADMIN_USER_TYPES, EMPLOYER_CONTACT_TYPE
} from "../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";
import EvoshareSignup from "../components/signup/employee/evoshareSignup";
import TermsAndConditionsSlide from "../components/signup/termsAndConditionsSlide";
import CompanyInfoSlide from "../components/signup/companyInfoSlide";
import BankDocumentSlide from "../components/signup/bankDocumentsSlide";
import OwnerWelcomeScreen from "../components/signup/ownerWelcomScreen";
import OwnerRegisterAccountSlide from "../components/signup/ownerRegisterAccountSlide";
import {appendScript, removeScript, uniqid} from "../_helpers";
import LoadingPopup from "../components/popups/loadingPopup";
import PlanDetailsSlide from "../components/signup/planDetailsSlide";
import ConfirmContributionsSlide from "../components/signup/employee/confirmContributionsSlide";
import EmployeeTermsAndConditionsSlide from "../components/signup/employee/employeeTermsAndConditionsSlide";

export default function Signup({layout}){
	const {code} = useParams();
	const history = useHistory();
	const [accountType,setAccountType] = useState('');
	const [user,setUser] = useState(null);
	const [listOfSteps,setListOfSteps] = useState(null);
	const [currentStep,setCurrentStep] = useState(0);
	const [showFaqPopup,setShowFaqPopup] = useState(false);
	const [organization,setOrganization] = useState();
	const [loading,setLoading] = useState(true);
	const [localAuthToken,setLocalAuthToken] = useState(null)
	const setGlobalUser = useStoreActions(actions=>actions.user.login)
	const setType = useStoreActions(actions=>actions.user.setType)
	const setToken = useStoreActions(actions => actions.user.updateToken);
	const [accountConfirmed,setAccountConfirmed] = useState(false);
	const activeOrganization_test = useStoreState(state=>state.user.activeOrganization);
	const setActiveOrganization = useStoreActions(actions=>actions.user.setActiveOrganization);
	const activeUser_test = useStoreState(state=>state.user.user);
	const [inviteRole,setInviteRole] = useState(null);
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const [plaidAccounts,setPlaidAccounts] = useState([]);
	const [contributionSettings,setContributionSettings] = useState(null)
	const [billingInfoIsSetUp,setBillingInfoIsSetUp] = useState(true);
	const [forteRiskId,setForteRiskId] = useState(null);
	const [showLoadingPopup,setShowLoadingPopup] = useState(false);
	const [loadingResponseMessage,setLoadingResponseMessage] = useState(null);

	const catchClose = (e) =>{
		e.preventDefault()
		let confirmationMessage = 'The Sign up process is not complete. You will need to use an invite to finish the sign up later if you leave.';

		e.returnValue = '';
	};

	const nextStep = () =>{
		if(accountType === EMPLOYEE_TYPE || accountType === EMPLOYEE_TYPE_NO_EVOSHARE){
			if(listOfSteps[currentStep] === 'elect-contributions'){
				if(organization && organization.plan_token === 'employer-direct'){
					setCurrentStep(Math.min(currentStep + 1, listOfSteps.length-1));
					return;
				}
				let orgId = organization.organization_id;
				let userId = user.user_id;
				if(code === 'test-employee'){
					orgId = activeOrganization_test.organization_id;
					userId = activeUser_test.user_id;
				}
				if(contributionSettings) {
					axios.post(contributionSaveSettingsUrl(orgId, userId), {user_contribution_settings: contributionSettings}, {headers: {'Auth-token': localAuthToken}}).then(response => {
						setCurrentStep(Math.min(currentStep + 1, listOfSteps.length-1));
					}).catch(e => {
					});
				}else{
					showMessage({message:'Contribution Settings required!',type:'error'})
				}
				return;
			}
			if(listOfSteps[currentStep] === 'register-accounts'){
				if(plaidAccounts.length>0){
					let totalAlloc = 0;
					plaidAccounts.forEach(acc=>{totalAlloc += parseInt(acc.account_allocation)});
					if(totalAlloc !== 100){
						showMessage({message:'Total Allocation Must Equal 100%',type:'error'});
						return;
					}
				axios.post('/organizations/'+organization.organization_id+'/users/'+user.user_id+'/accounts',{accounts:plaidAccounts},{headers: {'Auth-token': localAuthToken}}).then(response=>{
					let newStep = Math.min(currentStep+1,listOfSteps.length-1);
					if(listOfSteps[currentStep+1] === 'confirm-contributions' && organization.plan_token === 'employer-direct'){
						newStep=Math.min(currentStep+2,listOfSteps.length-1);
					}
					if(listOfSteps[currentStep+1] === 'profile-setup' && (!user.needs_profile || !organization.using_student_loans)){
						newStep=Math.min(currentStep+2,listOfSteps.length-1);
					}

					if(listOfSteps[newStep] === 'complete-setup'){
						acceptInvite();
						return;
					}
					setCurrentStep(newStep);
				}).catch(e=>{});
				}else{
					showMessage({message:'No Accounts Linked!',type:'error'});
					return;
				}

				return;
			}
			if(listOfSteps[currentStep+1] === 'confirm-contributions' && organization.plan_token === 'employer-direct'){

				setCurrentStep(Math.min(currentStep+2,listOfSteps.length-1));
				return;
			}

		}

		if(listOfSteps[currentStep+1] === 'complete-setup'){
			if(ADMIN_USER_TYPES.includes(accountType) && !billingInfoIsSetUp && code !== 'test-employer'){
				setShowLoadingPopup(true);
				let postData = {};
				postData.risk_id = forteRiskId;


				axios.post(inviteUrl + '/' + code + '/forte-application',postData).then(response=>{
					if(response.data.forte && response.data.forte.application_id){
						acceptInvite();
					}else{
						setLoadingResponseMessage({message:'No response from Forte.',type:'error'});
					}
				}).catch(e=>{
					let message = (e.response && e.response.data && e.response.data.meta)?e.response.data.meta.message || e.message : e.message;
					message = message || 'There was a problem sending your Forte application data.';
					setLoadingResponseMessage({message:message,type:'error'});
					setCurrentStep(listOfSteps.indexOf('info'));
				});
				return;
			}else {
				acceptInvite();
				return;

			}
		}

		if(listOfSteps[currentStep] === 'complete-setup'){
			acceptInvite();
			return;
		}
		setCurrentStep(Math.min(currentStep+1,listOfSteps.length-1));
	};
	const prevStep = () =>{
		if(listOfSteps[currentStep-1] === 'password-setup' && !user.user_needs_password){
			setCurrentStep(Math.max(currentStep-2,0));
			return;
		}
		if(listOfSteps[currentStep-1] === 'profile-setup' && (!user.needs_profile || !organization.using_student_loans)){
			setCurrentStep(Math.max(currentStep-2,0));
			return;
		}
		if(listOfSteps[currentStep-1] === 'confirm-contributions' && organization.plan_token === 'employer-direct'){
			setCurrentStep(Math.max(currentStep-2,0));
			return;
		}

		setCurrentStep(Math.max(currentStep - 1, 0));

	};
	const closeFaqPopup = () =>{
		setShowFaqPopup(false);
	};
	const acceptInvite = () =>{
		setShowLoadingPopup(true);
		let postData = {};
		if(forteRiskId){
			postData.risk_id = forteRiskId;
		}
		window.removeEventListener("beforeunload", catchClose);
		if(code !== 'test-employee' && code !== 'test-employer') {
			axios.post(inviteUrl + '/' + code + '/accept',postData).then(response => {
				const newToken = response.data.token;
				if(!newToken){
					setLoadingResponseMessage({message:'There was a problem logging you in, please try again.',type:'error'});
					history.push('/');
					return;
				}
				axios.post('/auth/validate',[],{headers: {'Auth-token': newToken}}).then(response=>{
						if(ADMIN_USER_TYPES.includes(accountType)){
							setType(ADMIN_TYPE)
						}else{
							setType(EMPLOYEE_TYPE)
						}
					setGlobalUser(response.data.user);
					setToken(newToken);
					setActiveOrganization(response.data.user.user_organization_roles.filter(role=>role.user_organization_role_id===inviteRole.user_organization_role_id)[0]);
					if((ADMIN_USER_TYPES.includes(accountType))){
						history.push('/company-account')
					}else {
						history.push('/dashboard');
					}

				}).catch(e=>{
					setLoadingResponseMessage({message:'There was a problem logging you in, please try again.',type:'error'});
					history.push('/');
				})

			}).catch(e => {
				let message = e.response && e.response.data && e.response.data.message? e.response.data.message : 'Unknown Error accepting invite, please try again.';
				setLoadingResponseMessage({message:message,type:'error'});
			});
		}else{
			setTimeout(()=>{
				setLoadingResponseMessage({message:'Test error message, screen will forward in 3 seconds.',type:'error'});
				setTimeout(()=>{history.push('/');},3000)
			},1000)
		}

	};

	useEffect(()=>{
		if(code === 'test-employee'){
			if(!activeOrganization_test){
				history.push('/');
				return;
			}
			setUser(activeUser_test);
			axios.get('/organizations/'+activeOrganization_test.organization_id).then(response=>{
				setOrganization(response.data.organization);
				setAccountType(EMPLOYEE_TYPE);
				setLoading(false);
			})
		}else if(code === 'test-employer'){
			if(!activeOrganization_test){
				history.push('/');
				return;
			}
			setBillingInfoIsSetUp(false);
			setUser(activeUser_test);
			axios.get('/organizations/'+activeOrganization_test.organization_id).then(response=>{
				setOrganization(response.data.organization);
				setAccountType(ADMIN_TYPE);
				axios.get('/organizations/'+response.data.organization.organization_id+'/validate-billing').then(response=>{
					// setBillingInfoIsSetUp(!!response.data.billing_info_is_set_up);
					if(!response.data.billing_info_is_set_up && response.data.forte_organization){
						const riskId =uniqid('',true)
						const forteUrl = `https://img3.forte.net/fp/tags.js?org_id=${response.data.forte_organization}&session_id=`;
						setForteRiskId(riskId);
						removeScript(forteUrl);
						appendScript(forteUrl + riskId + '&pageid=1')
					}
					setLoading(false);
				}).catch(e=>{})
			})
		}else {
			let cancelToken = axios.CancelToken.source();
			axios.get(inviteUrl + '/' + code, {cancelToken: cancelToken.token}).then(response => {
				setUser(response.data.user);
				setOrganization(response.data.organization);
				setLocalAuthToken(response.data.token);
				setInviteRole(response.data.role);

				setToken();
				if(ADMIN_USER_TYPES.includes(response.data.role.role_token)) {
					axios.get('/organizations/' + response.data.organization.organization_id + '/validate-billing', {headers: {'Auth-token': response.data.token}}).then(response => {
						setBillingInfoIsSetUp(!!response.data.billing_info_is_set_up);
						if(!response.data.billing_info_is_set_up && response.data.forte_organization){
							const riskId =uniqid('',true);
							const forteUrl = `https://img3.forte.net/fp/tags.js?org_id=${response.data.forte_organization}&session_id=`;
							setForteRiskId(riskId);
							removeScript(forteUrl);
							appendScript(forteUrl + riskId + '&pageid=1')
						}
						setLoading(false);
					}).catch(e => {
					})
				}else{
					if(response.data.role.role_token !== HR_TYPE && !response.data.organization.organization_contribution_setting_evoshare_employer){
						setInviteRole({role_token:EMPLOYEE_TYPE_NO_EVOSHARE});
					}
					setLoading(false);
				}

			}).catch(e => {
				history.push('/');
			});
			return () => {
				cancelToken.cancel('');
			}
		}

	},[code]);

	useEffect(()=>{
		if(!inviteRole){
			return;
		}
		if(code === 'test-employee'){
			setAccountType(EMPLOYEE_TYPE);
			return;
		}
		if(code === 'test-employer'){
			setAccountType(ADMIN_TYPE);
			return;
		}
		setAccountType(inviteRole.role_token)

	},[inviteRole]);

	useEffect(()=>{
		switch(accountType){
			case EMPLOYEE_TYPE:
				setListOfSteps(employeeSteps);
				break;
			case EMPLOYEE_TYPE_NO_EVOSHARE:
				setListOfSteps(employeeStepsNoEvoshare);
				break;
			case ADMIN_TYPE:
				setListOfSteps(billingInfoIsSetUp?employerAdminSteps:employerAdminStepsWithBilling);
				// setCurrentStep(6)
				break;
			case EMPLOYER_CONTACT_TYPE:
				setListOfSteps(billingInfoIsSetUp?employerAdminSteps:employerAdminStepsWithBilling);
				// setCurrentStep(5)
				break;
			case HR_TYPE:
				setListOfSteps(employerAdminSteps);
				break;
			default:
				if(accountType) {
					showMessage({message: 'Account Type ' + accountType + ' not found.', type: 'error'});
				}
				break;
		}
	},[accountType,billingInfoIsSetUp]);

	useEffect(()=>{
		window.addEventListener("beforeunload", catchClose);
		return () => {
			window.removeEventListener('beforeunload', catchClose)
		}
	},[])

	const closeLoadingPopup = () =>{
		setLoadingResponseMessage(null);
		setShowLoadingPopup(false);
	};

	const redirectHandler = (stepName) =>{
		setCurrentStep(Math.max(listOfSteps.indexOf(stepName),0));
	};

	return (
		<>
			{showLoadingPopup && <LoadingPopup loadingMessage={billingInfoIsSetUp?'Setting Up Account':'Submitting Application'} responseMessage={loadingResponseMessage} closeHandler={closeLoadingPopup} />}
			{showFaqPopup && <FaqPopup closeHandler={closeFaqPopup} /> }
			<SignupHeader user={user} currentStep={currentStep} type={accountType} organization={organization} includeBillingSteps={!billingInfoIsSetUp}/>

			<div className={'full-width page'}>
				{(!loading && listOfSteps) &&
				<div className={'max-width-container slide-holder'}>
					{accountType === EMPLOYEE_TYPE &&
					<>

						{listOfSteps[currentStep] === 'welcome' &&
						<WelcomeSlide user={user}
						              role={accountType}
						              organization={organization}
						              nextHandler={nextStep}
						              layout={layout}/>
						}



						{listOfSteps[currentStep] === 'welcome2' &&
						<WelcomeSlide2 user={user}
						               nextHandler={nextStep}
						               evoshareEligible={true}
						               redirectHandler={redirectHandler}
						               authToken={localAuthToken}
						               organization={organization}/>
						}

						{listOfSteps[currentStep] === 'password-setup' &&
						<AccountSetupSlide user={user}
						                   errorHandler={setAccountConfirmed}
						                   layout={layout}
						                   submit={accountConfirmed}
						                   authToken={localAuthToken}
						                   accountType={accountType}
						                   nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'terms' &&
						<EmployeeTermsAndConditionsSlide user={user}
						                                 layout={layout}
						                                 organization={organization}
						                                 authToken={localAuthToken}
						                                 nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'elect-contributions' &&
						<ElectContributionSlide user={user}
						                        updateUser={setUser}
						                        changeHandler={setContributionSettings}
						                        layout={layout}
						                        nextHandler={nextStep}
						                        organization={organization}
						                        authToken={localAuthToken}/>
						}

						{listOfSteps[currentStep] === 'confirm-contributions' &&
						<ConfirmContributionsSlide user={user}
						                           nextHandler={nextStep}
						                        organization={organization}
						                        authToken={localAuthToken}/>
						}

						{listOfSteps[currentStep] === 'register-accounts' &&
						<RegisterAccountsSlide changeHandler={setPlaidAccounts}
						                       layout={layout}
						                       authToken={localAuthToken}
						                       user={user}
						                       organization={organization}
						                       contributionSettings={contributionSettings}/>
						}

						{listOfSteps[currentStep] === 'evoshare' &&
						<EvoshareSignup user={user}
						                errorHandler={setAccountConfirmed}
						                layout={layout}
						                organization={organization}
						                authToken={localAuthToken}
						                nextHandler={nextStep}/>
						}


					</>
					}
					{accountType === EMPLOYEE_TYPE_NO_EVOSHARE &&
					<>

						{listOfSteps[currentStep] === 'welcome' &&
						<WelcomeSlide user={user}
						              role={accountType}
						              organization={organization}
						              nextHandler={nextStep}
						              layout={layout}/>
						}

						{listOfSteps[currentStep] === 'welcome2' &&
						<WelcomeSlide2 user={user}
						               evoshareEligible={false}
						               redirectHandler={redirectHandler}
						               authToken={localAuthToken}
						               nextHandler={nextStep}
						               organization={organization}/>
						}

						{listOfSteps[currentStep] === 'password-setup' &&
						<AccountSetupSlide user={user}
						                   errorHandler={setAccountConfirmed}
						                   layout={layout}
						                   submit={accountConfirmed}
						                   authToken={localAuthToken}
						                   accountType={accountType}
						                   nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'terms' &&
						<EmployeeTermsAndConditionsSlide user={user}
						                         layout={layout}
						                         organization={organization}
						                         authToken={localAuthToken}
						                         nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'elect-contributions' &&
						<ElectContributionSlide user={user}
						                        changeHandler={setContributionSettings}
						                        layout={layout}
						                        organization={organization}
						                        nextHandler={nextStep}
						                        authToken={localAuthToken}/>
						}

						{listOfSteps[currentStep] === 'confirm-contributions' &&
						<ConfirmContributionsSlide user={user}
						                        changeHandler={setContributionSettings}
						                        layout={layout}
						                           nextHandler={nextStep}
						                        organization={organization}
						                        authToken={localAuthToken}/>
						}

						{listOfSteps[currentStep] === 'register-accounts' &&
						<RegisterAccountsSlide changeHandler={setPlaidAccounts}
						                       layout={layout}
						                       authToken={localAuthToken}
						                       user={user}
						                       organization={organization}
						                       contributionSettings={contributionSettings}/>
						}


					</>
					}
					{(ADMIN_USER_TYPES.includes(accountType)  && !billingInfoIsSetUp) &&
					<>

						{listOfSteps[currentStep] === 'welcome' &&
						<OwnerWelcomeScreen organization={organization}
						                    user={user}
						                    nextHandler={nextStep}
						                    layout={layout}/>
						}

						{listOfSteps[currentStep] === 'password-setup' &&
						<AccountSetupSlide user={user}
						                   errorHandler={setAccountConfirmed}
						                   layout={layout}
						                   submit={accountConfirmed}
						                   authToken={localAuthToken}
						                   showContinueButton={true}
						                   accountType={accountType}
						                   continueAction={setAccountConfirmed}
						                   nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'plan-details' &&
						<PlanDetailsSlide organization={organization}
						                  authToken={localAuthToken}
						                   nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'terms' &&
						<TermsAndConditionsSlide user={user}
						                         layout={layout}
						                         organization={organization}
						                         authToken={localAuthToken}
						                         nextHandler={nextStep}/>
						}


						{listOfSteps[currentStep] === 'info' &&
						<CompanyInfoSlide user={user}
						                  layout={layout}
						                  authToken={localAuthToken}
						                  organization={organization}
						                  updateOrganization={setOrganization}
						                  nextHandler={nextStep}
						                  backHandler={prevStep}/>
						}

						{listOfSteps[currentStep] === 'documents' &&
						<BankDocumentSlide user={user}
						                   layout={layout}
						                   organization={organization}
						                   authToken={localAuthToken}
						                   nextHandler={nextStep}
						                   backHandler={prevStep}/>
						}

						{listOfSteps[currentStep] === 'register-account' &&
						<OwnerRegisterAccountSlide user={user}
						                   layout={layout}
						                   organization={organization}
						                   authToken={localAuthToken}
				                           updateOrganization={setOrganization}
						                   nextHandler={nextStep}
						                   backHandler={prevStep}/>
						}


					</>
					}
					{((ADMIN_USER_TYPES.includes(accountType) && !!billingInfoIsSetUp) || accountType === HR_TYPE) &&
					<>
						{listOfSteps[currentStep] === 'welcome' &&
						<WelcomeSlide organization={organization}
						              role={accountType}
						              user={user} nextHandler={nextStep}
						              layout={layout}/>
						}

						{listOfSteps[currentStep] === 'terms' &&
						<EmployeeTermsAndConditionsSlide user={user}
						                                 layout={layout}
						                                 organization={organization}
						                                 authToken={localAuthToken}
						                                 nextHandler={nextStep}/>
						}

						{listOfSteps[currentStep] === 'password-setup' &&
						<AccountSetupSlide user={user}
						                   errorHandler={setAccountConfirmed}
						                   layout={layout}
						                   submit={accountConfirmed}
						                   authToken={localAuthToken}
						                   showContinueButton={true}
						                   accountType={accountType}
						                   continueAction={setAccountConfirmed}
						                   nextHandler={nextStep}/>
						}
					</>
					}
				</div>
				}
			</div>
			<div className={'full-width signup-footer'}>
				<div className={'max-width-container'}>
				<button className={'faq-button'} onClick={()=>{setShowFaqPopup(true)}} ><span><span className={'help'} />FAQ</span></button>
				{((
					(currentStep>1 && (accountType === EMPLOYEE_TYPE))
					|| (accountType !== EMPLOYEE_TYPE && currentStep>0 && billingInfoIsSetUp)
					|| (!ADMIN_USER_TYPES.includes(accountType) && currentStep>0)
					|| (ADMIN_USER_TYPES.includes(accountType) && !billingInfoIsSetUp && (employerAdminStepsWithBilling[currentStep+1] === 'complete-setup' || employerAdminStepsWithBilling[currentStep] === 'complete-setup' || employerAdminStepsWithBilling[currentStep] === 'plan-details') )

				) && !hideProgressButtons.includes(listOfSteps[currentStep])) &&
					<div className={'progress-buttons'}>
						<button onClick={()=>{prevStep()}} className={'back-button'}><span>Back</span></button>

						{(listOfSteps && listOfSteps[currentStep] !== 'register-account') &&
							<button onClick={() => {
								if (listOfSteps[currentStep] === 'password-setup') {
									setAccountConfirmed(true);
								} else {
									nextStep()
								}
							}} className={'continue-button'}>
								<span>Continue</span></button>
						}

					</div>
				}
				</div>
			</div>
		</>
	)
}
const hideProgressButtons = [
	'welcome',
	'welcome2',
	'terms',
	'forte-terms',
	'profile-setup',
];
const employeeSteps = [
	'welcome',
	'password-setup',
	'terms',
	'welcome2',
	'elect-contributions',
	'confirm-contributions',
	'register-accounts',
	'evoshare',
	'complete-setup'
];
const employeeStepsNoEvoshare = [
	'welcome',
	'password-setup',
	'terms',
	'welcome2',
	'elect-contributions',
	'confirm-contributions',
	'register-accounts',
	'complete-setup'
];

const employerAdminSteps = [
	'welcome',
	'password-setup',
	'terms',
	'complete-setup'
];

const employerAdminStepsWithBilling = [
	'welcome',
	'password-setup',
	'plan-details',
	'terms',
	'info',
	'documents',
	'register-account',
	'complete-setup'
];
