import React, {useState} from 'react';
import ContactPopup from "./popups/contactPopup";
import {useStoreActions, useStoreState} from "easy-peasy";

export default function Footer(){

    const showContactForm = useStoreState(state=>state.modals.showContact);
    const setShowContactForm = useStoreActions(actions=>actions.modals.setShowContact);

    return (
        <>
            {showContactForm && <ContactPopup /> }
            <div className={'footer full-width'}>
                <div className={'max-width-container'}>
                    <div className={'logo-holder'}>
                        <img src={'/images/logo-no-text.svg'} alt="" />
                        <span>© {new Date().getFullYear()} Thrive, LLC.</span>
                    </div>
                    <a href={'#'} onClick={e=>{e.preventDefault();setShowContactForm(true)}}>
                        Contact Us
                    </a>
                </div>
            </div>
        </>
    )
}
