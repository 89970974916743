import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import { formatDate } from '../../_helpers';

export default function TerminatedDuplicatePopup({closeHandler, response, submitHandler}){


    return (
        <Popup closeHandler={closeHandler} bodyClass={'evoshare-result'} >
            <div className={'popup-content evoshare-result-popup document-details'} >
            <div style={{padding:'20px'}} className={'body'}>
                    <p style={{padding:'0 24px', fontSize:'20px', fontWeight:'500'}}>
                        A previously terminated Employee with the same Employee ID was found with the email address:
                    </p>
                    <ul>
                        <li>
                            {response.profile_first_name} {response.profile_last_name}
                        </li>
                        <li>
                            {response.user_email}
                        </li>
                        <li>
                            started: {formatDate(response.user_organization_start_date)}
                        </li>
                        
                    </ul>
					<br/>
					<br/>
					<button className={'simple-rounded-button small fixed green'} onClick={() => {
                            submitHandler(true)
                        }}><span>Replace with new Employee</span>
                        </button>
					<br/>
					<br/>
					<button className={'simple-rounded-button small fixed red'} onClick={() => {
                            submitHandler(false)
                        }}><span>Cancel</span>
                        </button>
				</div>
            
            </div>
        </Popup>
    )
}
