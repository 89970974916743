import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Prompt} from "react-router";
import ProviderSelector from "../../../components/providerSelector";
import InstitutionSelectorPopup from "../../../components/popups/institutionSelectorPopup";
import RegisterAccountPopup from "../../../components/popups/registerAccountPopup";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import {plaidEmployeeTokenUrl} from "../../../_constants";
import NumberFormat from "react-number-format";
import BankInfoPopup529 from "../../../components/popups/bankInfoPopup";
import LoanAccountPopup from "../../../components/popups/loanAccountPopup";
import ManualLoanOptionPopup from "../../../components/popups/manualOptionPopup";
import ManualSavingsPopup from "../../../components/popups/manualSavingsPopup";
import SavingsMicroDepositsPopup from "../../../components/popups/savingsMicroDepositsPopup";
import useAlertOnUnsaved from "../../../hooks/useAlertOnUnsaved"
import SpinwheelLoansPopup from '../../../components/popups/spinwheelLoansPopup';
import useSpinwheelDim from '../../../hooks/useSpinwheelDim';
import ContactPopup from '../../../components/popups/contactPopup';
import EditAccountNumberPopup from '../../../components/popups/editAccountNumberPopup';

export default function EmployeeAccounts(){

	const FLAT_ALLOCATION = 'flat';
	const PERCENT_ALLOCATION = 'percentage';

	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const setLastUpdated = useStoreActions(actions=>actions.headerInfo.setLastUpdated);
	const [plaidHandler,setPlaidHandler] = useState(null);
	const [showNewAccountPopup,setShowNewAccountPopup] = useState(false);
	const [selectedAccountType,setSelectedAccountType] = useState('');
	const [accountsResponse,setAccountsResponse] = useState(null);
	const [linkedAccounts,setLinkedAccounts] = useState([]);
	const [showInstitutionPopup,setShowInstitutionPopup] = useState(false);
	const user = useStoreState(state=>state.user.user);
	const authToken = useStoreState(state=>state.user.token);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const [show529Popup,setShow529Popup] = useState(false);
	const [showManualLoanPopup,setShowManualLoanPopup] = useState(false);
	const [showManualSavingsPopup,setShowManualSavingsPopup] = useState(false);
	const [connectionItem,setConnectionItem] = useState(null);
	const [showManualOptionPopup,setShowManualOptionPopup] = useState(false);
	const [showDepositsForm,setShowDepositsForm] = useState(false);
	const [showAdvanced,setShowAdvanced] = useState(false);
	const [allocationSelections,setAllocationSelections] = useState({});
	const [showContactPopup,setShowContactPopup] = useState(false);
	const [contributionSettings,setContributionSettings] = useState(null);
	const [selectedAccount,setSelectedAccount] = useState(null);
	const [unsavedChanges,setUnsavedChanges] = useState(false);
	const [editingAccount,setEditingAccount] = useState(null);
	const [,setUpdateKey] = useState(0);

	const [showSpinwheelAccountSelect,  initSpinwheelDim, closeSpinwheelAccountSelect, spinwheelServicerIds, spinwheelConnectionType] = useSpinwheelDim(authToken,setShowManualOptionPopup);

	useAlertOnUnsaved(unsavedChanges);
	const manualOptionCloser = (openAddPopup = false) =>{
		setShowManualOptionPopup(false);
		if(openAddPopup){
			switch(selectedAccountType){
				case 'student':
					initSpinwheelDim(true,true);
					break;
				case 'savings':
					setShowManualSavingsPopup(true);
					break;
				default:
					break;
			}

		}else{
			setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
		}
	}

	const manualLoanPopup = (newConnectionItem = null) =>{
		setShow529Popup(false);
		setShowInstitutionPopup(false);
		setShowNewAccountPopup(false);
		setConnectionItem(newConnectionItem)
		setShowManualLoanPopup(true);
	}

	const manualPopupResponseHandler = (response) =>{
		if(response.data.account){
			setUnsavedChanges(true);
			setLinkedAccounts([...linkedAccounts, response.data.account]);
		}
		setShowManualLoanPopup(false);
		setShowManualSavingsPopup(false);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const depositsPopupResponseHandler = (response) =>{
		if(response.data.account_id){
			setLinkedAccounts(linkedAccounts.map(account=>{
				if(account['account_id'] === response.data.account_id){
					account['savings_account_verified'] = true;
				}
				return account
			}))
		}
		setShowDepositsForm(false);
	}


	const onExit = () => {
		if(activeOrganization['organization_allow_manual_savings'] === 'yes') {
			setShowManualOptionPopup(true);
		}
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const onStudentExit = () => {
		setShowManualOptionPopup(true);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const onSuccess = useCallback((token, metadata) => {
		axios.post('/plaid/accounts',{public_token:token}).then(response=>{
			setAccountsResponse(response.data);
			setShowNewAccountPopup(true);
		}).catch(e=>{})
	}, []);

	useEffect(()=>{
		const plaidCancelToken = axios.CancelToken.source();
		const accountsToken = axios.CancelToken.source();
		setLastUpdated('...');
		axios.post(plaidEmployeeTokenUrl, {}, {cancelToken:plaidCancelToken.token}).then(result=>{
			if (result.error != null) {
				showMessage({type:'error',message: result.error});
				return;
			}
			setPlaidHandler(window.Plaid.create({
				token: result.data.link_token,
				onSuccess: onSuccess,
				onExit:onExit
			}));
		});
		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/contribution-settings').then(response=>{
			if(!response?.data?.user_contribution_settings){
				setContributionSettings([]);
				return;
			}
			setContributionSettings(response.data.user_contribution_settings);
			updateAccounts(response.data.user_contribution_settings);
		}).catch(e=>{});


		return ()=>{
			plaidCancelToken.cancel('');
			accountsToken.cancel('');
		}
	},[]);

	async function updateAccounts(contrSettings = null){
		contrSettings = contrSettings || contributionSettings;
		if(!contrSettings){
			console.log('no contribution settings')
			return;
		}
		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/accounts').then(response=>{
			let existingAccounts = response.data.accounts;
			let lastUpdated = 0;
			let forceAdvanced = false;
			existingAccounts.forEach(account=>{
				if(account.account_allocation_type === FLAT_ALLOCATION){
					forceAdvanced = true;
				}
				if(account.account_allocation && !account.account_flat_allocation){
					account.account_flat_allocation = Math.round((account.account_allocation/100) * contrSettings.user_contribution_setting_program_total_flat);
				}
				if(account.account_timestamp_updated) {
					let date = account.account_timestamp_updated?new Date(account.account_timestamp_updated.replace(' ','T')).valueOf():0;
					lastUpdated = Math.max(lastUpdated, date);
				}
			});
			setShowAdvanced(showAdvanced || forceAdvanced);
			if(lastUpdated>0){
				setLastUpdated(new Date(lastUpdated).toLocaleDateString())
			}else{
				setLastUpdated('hide');
			}
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});

		}).catch(e=>{});
	}

	useEffect(()=>{
		if(linkedAccounts && linkedAccounts.length === 1 && linkedAccounts[0].account_allocation == 0){
			updateAccountAllocation(100,linkedAccounts[0]);
			saveSettings();
		}
	},[linkedAccounts])

	const plaidButtonHandler = (accountType) =>{

		setSelectedAccountType(accountType);

		plaidHandler.open();
	};

	const registerAccountPopupCloser = (account,institution) =>{
		if(!account || !institution){
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			showMessage({message:'Could not retrieve account or bank info, please try again later',type:'error'});
			return;
		}
		const postData = {
			account:account,
			item:accountsResponse.item,
			connection_id:accountsResponse.connection_id,
			institution:institution,
			organization_id:activeOrganization.organization_id
		};
		axios.post('/plaid/register-account',postData).then(response=>{
			setShowNewAccountPopup(false);
			setAccountsResponse(null);
			if(linkedAccounts.filter(acc=>acc.plaid_account_id === response.data.account.plaid_account_id).length>0){
				showMessage({message:'Account Already Listed',type:'error'});
			}else {
				setUnsavedChanges(true);
				setLinkedAccounts([response.data.account, ...linkedAccounts]);
			}
			setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
		}).catch(e=>{});

	};

	const popupResponseHandler_529 = (response) =>{
		if(response.data.account){
			setLinkedAccounts([response.data.account, ...linkedAccounts]);
			setUnsavedChanges(true);
		}
		setShow529Popup(false);
		setTimeout(()=>{document.body.style.overflow = 'scroll';},30);
	};

	const getAccountType = (account) =>{

		switch (account.account_type_token) {
			case 'student':
				return 'Student Loan';
			case 'savings':
				return 'Emergency Savings Account';
			case '529':
				return 'College savings plan';
			case 'education savings account':
				return 'Tax-advantaged Education Savings Account (ESA)';
			default:
				return 'Account';
		}
	};

	const getTotalFlatAvailable = useMemo(()=>{
		return contributionSettings?.user_contribution_setting_program_total_flat || 0;
	},[contributionSettings]);

	const getTotalComp = useMemo(()=>{
		return contributionSettings?.user_contribution_setting_eligible_compensation || 0;
	},[contributionSettings]);


let allocUpdateRunning = false;

	function updateAccountAllocation(value,newAccount, isFlat = false){
		if(allocUpdateRunning){
			return;
		}
		allocUpdateRunning = true;
			setLinkedAccounts(state=> state.map(account=>{

				if((account.plaid_account_id && account.plaid_account_id === newAccount.plaid_account_id) || (account.account_id && account.account_id === newAccount.account_id) || (account.spinwheel_account_id && account.spinwheel_account_id === newAccount.spinwheel_account_id)){

					const selectedType = getAccountAllocationSelection(account);
					account.account_allocation_type = selectedType;
					if(selectedType === FLAT_ALLOCATION && isFlat){
						if(String(value) === String(account.account_flat_allocation)){
							return account;
						}

						account.account_flat_allocation = value;
						account.account_allocation = getTotalFlatAvailable > 0 ? Math.round((value/ getTotalFlatAvailable) * 100) : 0;
					}else if(selectedType === PERCENT_ALLOCATION && !isFlat){
						if(String(value) === String(account.account_allocation)){
							return account;
						}
						account.account_allocation = value;
						account.account_flat_allocation = Math.round((value/100) * getTotalFlatAvailable);
					}
				}
				return account;
			}));
			setUnsavedChanges(true);
			allocUpdateRunning = false;

	};

	const totalAllocation = useMemo(()=>{
		let total = 0;
		linkedAccounts.forEach(account=>{
			total += parseInt(account.account_allocation || 0);
		});
		return total;
	},[linkedAccounts]);

	const totalFlatAllocation = useMemo(()=>{
		let total = 0;
		linkedAccounts.forEach(account=>{
			total += parseFloat(account.account_flat_allocation || 0);
		});
		return total;
	},[linkedAccounts]);

	const institutionPopupCloseHandler = (data) => {
		setShowInstitutionPopup(false);
		if(!data){
			return;
		}
		if(data === 'new'){
			plaidHandler.open();
			return;
		}
		if(data.accounts){
			setAccountsResponse(data);
			setShowNewAccountPopup(true);
		}
	};

	const reallocateDeletedAllocation = (deletedAllocation,remainingAccounts) =>{
		setUnsavedChanges(true);
		let split = Math.round(parseInt(deletedAllocation)/remainingAccounts.length);
		let numbOfRemainingAccounts = remainingAccounts.length;
		let totalAlc = 0;
		remainingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
		let remainder = (100 - totalAlc) - (split * numbOfRemainingAccounts);
		return remainingAccounts.map((account,index)=>{
			account.account_allocation = parseInt(account.account_allocation)+split;
			if(index===0 && remainder){
				account.account_allocation += remainder;
			}
			return account;
		});
	};

	const removeAccount = (account) =>{
		if(!account.account_id){
			let existingAccounts = linkedAccounts.filter(a=>a.plaid_account_id !== account.plaid_account_id);
			existingAccounts = reallocateDeletedAllocation(account.account_allocation,existingAccounts);
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			return;
		}
		axios.delete('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/accounts/'+account.account_id).then(response=>{
			let existingAccounts = linkedAccounts.filter(a=>a.account_id !== account.account_id);
			existingAccounts = reallocateDeletedAllocation(account.account_allocation,existingAccounts);
			setLinkedAccounts(existingAccounts);
			let totalAlc = 0;
			existingAccounts.forEach(acc=>{totalAlc += parseInt(acc.account_allocation || 0)});
			if(existingAccounts.length > 0) {
				saveSettings(existingAccounts)
			}else{
				setUnsavedChanges(false);
			}
		}).catch(e=>{})
	};

	const saveSettings = (accounts = null) =>{
		let postAccounts = accounts || linkedAccounts;
		axios.post('/organizations/'+activeOrganization.organization_id+'/users/'+user.user_id+'/accounts',{accounts:postAccounts}).then(response=>{
			if(!response.data.meta.message) {
				showMessage({message: 'Account Allocation Settings Saved.'});
			}
			setUnsavedChanges(false);
			updateAccounts();
		}).catch(e=>{});
	};

	const checkForAccountType = (typeToken) =>{
		if(!activeOrganization){
			return false;
		}
		return activeOrganization.organization_account_types.some(at=>at.account_type_token === typeToken);
	};

	const handleCloseSpinwheelAccount = async (newAccount, newAccountNumberUpdate = null) => {
		let [newLinkedAccounts, accountsChanged] = await closeSpinwheelAccountSelect(newAccount, linkedAccounts, newAccountNumberUpdate);
		if(!accountsChanged){
			return;
		}
		newLinkedAccounts = newLinkedAccounts.filter(a=>!a.loan_account_rightfoot_uuid && !a.plaid_account_rightfoot_uuid);
		setLinkedAccounts(newLinkedAccounts);
	}

	function moveAccountUp(index){
		if(index === 0){
			return;
		}
		let newAccounts = [...linkedAccounts];
		let temp = newAccounts[index-1];
		newAccounts[index-1] = newAccounts[index];
		newAccounts[index] = temp;
		setLinkedAccounts(newAccounts);
		setUnsavedChanges(true);
	}

	function moveAccountDown(index){
		if(index === linkedAccounts.length-1){
			return;
		}
		let newAccounts = [...linkedAccounts];
		let temp = newAccounts[index+1];
		newAccounts[index+1] = newAccounts[index];
		newAccounts[index] = temp;
		setLinkedAccounts(newAccounts);
		setUnsavedChanges(true);
	}

	function getAccountAllocationSelection(account){
		return account.account_allocation_type || PERCENT_ALLOCATION;
	}

	function setAccountAllocationSelection(account, selection){
		if(selection === FLAT_ALLOCATION){
			let foundPercentage = false;
			linkedAccounts.forEach(acc=>{
				if(acc.account_id !== account.account_id && acc.account_allocation_type === PERCENT_ALLOCATION){
					foundPercentage = true;
				}
			});
			if(!foundPercentage){
				showMessage({message:'You must have at least one account with a percentage allocation.',type:'error'});
				return;
			}
		}
		setLinkedAccounts(state => state.map(acc=>{
			if(acc.account_id === account.account_id){
				acc.account_allocation_type = selection;
			}
			return acc;
		}));
	}

	function areAnyAllocationsFlat(){
		return linkedAccounts.some(account=>account.account_allocation_type === FLAT_ALLOCATION);
	}

	function toggleShowAdvanced(){
		if(areAnyAllocationsFlat()){
			setShowAdvanced(true);
			return;
		}
		setShowAdvanced(!showAdvanced);
	}

	function formatBalance(account){
		if(account.account_balance){
			return '(' + new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(account.account_balance) + ')';
		}
		return '';
	}

	function getAccountNumberLabel(account){
		const acn = account.liabilityProfile?.accountNumber || account.spinwheel_account_number || account.plaid_account_mask || false;
		if(!acn){
			return '';
		}
		const requiresNumber = account.spinwheel_account_require_number;
		if(requiresNumber != 0 && !!account.spinwheel_account_student_loan_id){
			return <>
				<br/>
				<button className='edit-account-btn' onClick={()=>{setEditingAccount(account)}} style={{display:'flex',alignItems:'center',gap:8}}>
					<img src={'/images/pencil-box-outline.svg'} alt={'edit account number'} style={{maxWidth:24,height:24}} />
					Account #:{acn}
					</button>
			</>
		}
		return <>
				<br/>
				<span className='account-num'>
				Account #:{acn}
				</span>
			</>
	}

	function handleEditAccountNumber(updatedAccountNumber){
		if(!updatedAccountNumber){
			setEditingAccount(null);
			return;
		}
		setLinkedAccounts(linkedAccounts.map(account=>{
			if(account.account_id === editingAccount.account_id){
				account.spinwheel_account_number = updatedAccountNumber;
			}
			return account;
		}));
		setEditingAccount(null);
		// setUpdateKey(Math.random());
	}

	return (
		<>
			<Prompt
				when={unsavedChanges}
				message={() => 'You have unsaved changes, are you sure you want to leave?'}
			/>
			{editingAccount && <EditAccountNumberPopup account={editingAccount} closeHandler={handleEditAccountNumber} />}
			{show529Popup && <BankInfoPopup529 sucessHandler={popupResponseHandler_529} closeHandler={()=>{setShow529Popup(false)}} organization={activeOrganization} user={user} />}
			{showInstitutionPopup && <InstitutionSelectorPopup subtype={selectedAccountType} closeHandler={institutionPopupCloseHandler} />}
			{showNewAccountPopup && <RegisterAccountPopup addAccountManual={manualLoanPopup} small={true} data={accountsResponse} subType={selectedAccountType} closeHandler={registerAccountPopupCloser} />}
			{showManualLoanPopup && <LoanAccountPopup organization={activeOrganization} subType={selectedAccountType} sucessHandler={manualPopupResponseHandler} closeHandler={()=>{setShowManualLoanPopup(false)}} user={user}/>}
			{showManualSavingsPopup && <ManualSavingsPopup organization={activeOrganization} subType={selectedAccountType} user={user} sucessHandler={manualPopupResponseHandler} closeHandler={()=>{setShowManualSavingsPopup(false)}} />}
			{showManualOptionPopup && <ManualLoanOptionPopup closeHandler={manualOptionCloser} />}
			{showDepositsForm && <SavingsMicroDepositsPopup closeHandler={()=>{setShowDepositsForm(false)}} sucessHandler={depositsPopupResponseHandler} organization={activeOrganization} user={user} accountId={selectedAccount}  />}
			{showSpinwheelAccountSelect && <SpinwheelLoansPopup closeHandler={handleCloseSpinwheelAccount}
																servicerIds={spinwheelServicerIds}
																authToken={authToken}
																triggerBankLogin={()=>{ initSpinwheelDim(true, true) }}
																showContactPopup={()=>{setShowContactPopup(true)}}
																connectionType={spinwheelConnectionType}/>}
			{showContactPopup && <ContactPopup closeHandler={()=>{setShowContactPopup(false)}} />}
			{linkedAccounts.length > 1 &&
			<div style={{border:"none",padding:0}} className={'account-block'}>
				<div style={{display:"flex",justifyContent:"flex-end",alignContent:"center",gap:12,opacity:areAnyAllocationsFlat()?0.4:1}} onClick={() => {
							toggleShowAdvanced()
						}}>
							Show Advanced Settings
							<span className={'checkbox ' + (showAdvanced ? 'checked ' : ' ')}/>
				</div>
			</div>
			}
			<div className={'account-block'}>
				<div className={'add-account-holder'}>
					<div className={'add-account-buttons'}>
						{checkForAccountType('529') && <button onClick={()=>{setShow529Popup(true)}} ><span><strong>+</strong> Add 529</span></button>}
						{(plaidHandler) &&
						<>
							{checkForAccountType('emergency') && <button onClick={()=>{plaidButtonHandler('savings')}} ><span><strong>+</strong> Add Emergency Savings</span></button>}
						</>
						}
						{checkForAccountType('student') && <button onClick={()=>{initSpinwheelDim(true, true)}}><span><strong>+</strong> Add Student Loan</span></button>}
					</div>
				</div>
			</div>
			{showAdvanced &&
				<div className={'account-block'} style={{backgroundColor:'rgba(255, 255, 224, 0.5)',opacity:0.7}}>
					<h3>Advanced Settings</h3>
					<ul>
						<li>Allocations can be set as dollar amounts or percentages.</li>
						<li>Dollar to percentage calculation is based on expected total payment seen in contribution settings</li>
						<li>Use the arrows on the left to change the account order.</li>
						<li>Accounts are prioritized from top to bottom in the case of a payment not matching the expected total amount.</li>
						<li>Click on the allocation type you want to use to change from percent to dollar, or vice-versa. If any account has a dollar allocation only the advanced view is enabled.</li>
					</ul>
				</div>

			}
			{checkForAccountType('student') &&
				<div style={{fontSize:12,opacity:".7"}} >
					* For student loans: Your loan servicer will have its own way of allocating these loans. This is only for when you have loans with different servicers.  The allocation still must add up to 100%
				</div>

			}

			{linkedAccounts.filter(acc=>!!acc).map((account,index)=>{
				let accountName = account.account_name + (account.plaid_account_mask?' ('+account.plaid_account_mask+')':'') + formatBalance(account)
				return(
					<div key={(account.plaid_account_id || account.spinwheel_account_id || account.account_id) + index} className={'account-block ' + ((!account['savings_account_verified'] || account['plaid_account_rightfoot_uuid'] || account['loan_account_rightfoot_uuid'])?'unverified':'')}>
						{showAdvanced &&
							<div class='priority-arrows'>
								<button onClick={()=>moveAccountUp(index)}><img style={{transform:"rotate(180deg)"}} src='/images/down-chevron-blue.svg' alt="move account up"/></button>
								<button onClick={()=>moveAccountDown(index)}><img src='/images/down-chevron-blue.svg' alt="move account down"/></button>
							</div>
						}
						<div className={'account-title'}>
							<h2>{index+1}. {getAccountType(account)}
								{getAccountNumberLabel(account)}
							</h2>
							<button onClick={()=>{removeAccount(account)}}>Remove</button>
						</div>

						<div className={'account-details '+(account['savings_account_verified']?'':'unverified ')}>
							{account.institution_name &&
								<label>
									Provider
									<ProviderSelector inputDisabled={true} isDisplayOnly={true} options={[{label:account.institution_name,value:account.pliad_institution_id,logo:account.plaid_institution_logo?'data:image/png;base64,'+account.plaid_institution_logo:(account.logo || null)}]} changeHandler={()=>{}} />
								</label>
							}
							<label>
								Account
								<ProviderSelector inputDisabled={true} isDisplayOnly={true} options={[{label:accountName ,value:account.account_name,logo:null}]} changeHandler={()=>{}} />
							</label>
							<div className={"advanced-allocations " + (showAdvanced ? ' two-col': '')}>
								<label style={{width:'100%'}}>
									Allocation {account['savings_account_verified']?'':'*'}
									<div className={'input-holder dollar ' + (showAdvanced && getAccountAllocationSelection(account) !== PERCENT_ALLOCATION ? 'disabled':'') }>
										<div className={'input'} onClick={()=>setAccountAllocationSelection(account,PERCENT_ALLOCATION)}>
											<NumberFormat value={account.account_allocation}
														thousandSeparator={true}
														decimalScale={0}
														maxLength={3}
														onValueChange={(values) => {
															const {formattedValue, value} = values;
															updateAccountAllocation(value,account);
														}}
											/>
											<span>%</span>
										</div>
									</div>
								</label>
								{showAdvanced &&
								<label style={{width:'100%',maxWidth:'100%'}}>
								<div className={'input-holder dollar flipped ' + (showAdvanced && getAccountAllocationSelection(account) !== FLAT_ALLOCATION ? 'disabled':'')}>
									<div className={'input'} onClick={()=>setAccountAllocationSelection(account,FLAT_ALLOCATION)}>
									<span>$</span>
										<NumberFormat value={account.account_flat_allocation}

													allowEmptyFormatting={true}
													thousandSeparator={true}
													decimalScale={2}
													maxLength={6}
													onValueChange={(values) => {
														const {formattedValue, value} = values;
														updateAccountAllocation(value,account, true);
													}}
										/>

									</div>
								</div>
							</label>
							}
						</div>

						</div>
						{!account['savings_account_verified'] &&
							<div style={{marginTop:10}}>
								<button  className={'clickable simple-rounded-button green'} onClick={()=>{setSelectedAccount(account['account_id']); setShowDepositsForm(true);}} >
									<span>Verify Your micro-deposits</span>
								</button>
								<span style={{marginLeft:'10px'}}>* 2 micro-deposits were made to this account and must be confirmed before payments can be made.</span>
							</div>

						}
					</div>
				)

			})
			}
			{linkedAccounts.length === 0 &&
				<div className={'account-block empty'}>
					<div>
						<h2>No Accounts Registered</h2>
						<p>Please Connect an Account</p>
					</div>
				</div>

			}
			{(showAdvanced && getTotalFlatAvailable > 0) &&
				<div style={{fontSize:12,textAlign:'right',paddingRight:12,paddingBottom:12}} >
					Your estimated total payment is ${getTotalFlatAvailable}, based on your contribution settings.
				</div>
			}
			{linkedAccounts.length > 0 &&
			<>
				<div className={'contribution-disclosure accounts in-slide'}>
					Contribution Amount Allocated
					<NumberFormat value={totalAllocation}
						thousandSeparator={true}
						className={totalAllocation !== 100?'error':''}
						decimalScale={0}
						suffix={'%'}
						displayType={'text'}/>
					{showAdvanced &&
					<NumberFormat value={totalFlatAllocation}
						thousandSeparator={true}
						decimalScale={2}
						prefix={'$'}
						displayType={'text'}/>
					}
				</div>

				<div className={'row'}>
					<button onClick={()=>{saveSettings()}} className={'simple-rounded-button blue'}><span>Save Changes</span>
					</button>
				</div>
			</>
			}
		</>
	)
}
