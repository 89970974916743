import React, {useEffect, useState, useRef} from 'react';
import axios from "axios";

export default function DownloadCsv({url, label, downloadName, apiParams = {}, ...props}) {

    const [downloadHref, setDownloadHref] = useState(null);
    const downloadCsvAnchorRef = useRef();

    const downloadCsv = async () => {
        const windowUrl = window.URL || window.webkitURL;
        const {data} = await axios.get(url, {params: apiParams});
        const blob = new Blob([data], {type: 'text/csv'});
        setDownloadHref(windowUrl.createObjectURL(blob));
    };

    useEffect(() => {
        if (downloadHref) {
            downloadCsvAnchorRef.current.click();
            setDownloadHref(null);
        }
    }, [downloadHref]);

    useEffect(() => {
        if(downloadCsvAnchorRef.current) {
            downloadCsvAnchorRef.current.addEventListener('click', e => {
                e.stopPropagation();
            })
        }
    }, [])

    return (
        <span onClick={() => downloadCsv()} {...props}>
            {label}
            <a ref={downloadCsvAnchorRef} href={downloadHref} download={downloadName}/>
        </span>
    );
}
