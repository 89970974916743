import React, {useState} from 'react';
import ServiceAgreementPopup from "../popups/serviceAgreementPopup";

export default function AuthFooter(){

	const [showAgreementPopup,setShowAgreementPopup] = useState(false);

	return (
		<>
			{showAgreementPopup && <ServiceAgreementPopup closeHandler={()=>{setShowAgreementPopup(false)}} />}
			<div className={'footer full-width signup-footer auth-footer'}>
				<div className={'max-width-container'}>
					<div className={'row'} >
						<img className={'logo'} src={'/images/logo-all-white.svg'} />
						<span onClick={()=>{setShowAgreementPopup(true)}} className={'service-agreement'}>Service Agreement</span>
					</div>
					<div className={'footer-contact-row'}>
						<div>
							<a href={'https://www.google.com/maps/place/28601+Chagrin+Blvd+%23550,+Woodmere,+OH+44122/@41.462817,-81.478014,19.1z/data=!4m5!3m4!1s0x883102de6c0cdffd:0x6dea4d3ff9311895!8m2!3d41.4629682!4d-81.4775863'}>
								28601 Chagrin Blvd. | Suite 550 | Woodmere, OH 44122
							</a>
							<a href={'tel:216-595-0700'}>
								216-595-0700
							</a>
							<a href={'mailto:support@thrivematching.com'}>
								Support@Thrivematching.com
							</a>
						</div>
						<span>© {new Date().getFullYear()} Thrive, LLC.</span>
					</div>
				</div>

			</div>
		</>
	)
}
