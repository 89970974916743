import React, {useEffect, useState} from 'react';
import Popup from "./popup";
import "react-datepicker/dist/react-datepicker.css";
import {Colors} from "../../_constants";
import axios from 'axios';
import {useStoreState} from "easy-peasy";

export default function ActivityPopup({closeHandler,activity}){

	const [changedByDetails, setChangedByDetails] = useState(null);
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const activeUser = useStoreState(state=>state.user.user);
	useEffect(()=>{
		console.log(activity);
		if(activity && activity.changed_by_id === activeUser.user_organization_id){
			setChangedByDetails({profile_first_name:'User'});
			return;
		}
		if(!activity || !activity.changed_by_id){
			setChangedByDetails(null);
			return;
		}
		axios.get('/organizations/'+activeOrganization.organization_id+'/users/'+activity.user_id+'/activity/changed-by?user_org_id='+activity.changed_by_id).then(response=>{
			if(response.data){
				setChangedByDetails(response.data);
			}
		}).catch(e=>{})

	},[activity]);

	return(
		<Popup closeHandler={closeHandler}  >
			<div className={'employee-settings popup-content'}>
				<h2 style={{color:Colors.blue}} className={'title'}>Activity Record</h2>
				<div className={'popup-form employee'}>
					<label className={'full-row'}>
						Message
						<div className={'read-only text-area'}>
							{activity && activity.activity_message}
						</div>
					</label>
					{activity?.payment_timestamp &&
					<label>
						Payment Initiated
						<div className={'read-only'}>
							{activity && (new Date(activity.payment_timestamp.replace(' ','T')).toLocaleDateString() + ':' +new Date(activity.payment_timestamp.replace(' ','T')).toLocaleTimeString())}
						</div>
					</label>
					}
					{activity?.transaction_id &&
					<label>
						Transaction ID
						<div className={'read-only'}>
							{activity.transaction_id}
						</div>
					</label>
					}
					<label>
						Activity Timestamp
						<div className={'read-only'}>
							{activity && (new Date(activity.activity_timestamp.replace(' ','T')).toLocaleDateString() + ':' +new Date(activity.activity_timestamp.replace(' ','T')).toLocaleTimeString())}
						</div>
					</label>
					<label>
						Status
						<div className={'read-only'}>
							{activity && activity.activity_status}
						</div>
					</label>
					<label>
						Type
						<div className={'read-only'}>
							{activity && activity.activity_type_title}
						</div>
					</label>
					{(activity && activity.confirmation_number) &&
					<label>
						Confirmation #
						<div className={'read-only'}>
							{activity.confirmation_number}
						</div>
					</label>
					}
					<label>
						For User
						<div className={'read-only'}>
							{activity && (activity.profile_first_name + ' ' + activity.profile_last_name)}
						</div>
					</label>
					{changedByDetails &&
					<>
						{changedByDetails.profile_first_name &&
						<label>
							By User
							<div className={'read-only'}>
								{changedByDetails.profile_first_name + (changedByDetails.profile_last_name?' '+ changedByDetails.profile_last_name:'')}
							</div>
						</label>
						}
					</>
					}
				</div>
			</div>
		</Popup>
	)
}
