import React from 'react';
import Popup from "./popup";
import {useHistory} from "react-router";

export default function WarningPopup({closeHandler,message}){


	return (
		<Popup closeHandler={closeHandler} bodyClass={'evoshare-result'}>
			<div style={{textAlign:'center'}} className={'popup-content thrive-description'} >
				<h1>Warning</h1>
				<div style={{padding:'20px'}} className={'body'}>
					{message}
					<br/>
					<br/>
					<button onClick={()=>{closeHandler(true)}} className={'simple-rounded-button small fixed green'}><span>Continue</span></button>
					<br/>
					<br/>
					<span onClick={()=>{closeHandler(false)}} className={'clickable standard-link green'}>Cancel</span>
				</div>
			</div>
		</Popup>
	)
}
