import React from 'react';
import Popup from "./popup";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import {useHistory} from "react-router";

export default function AlertsPopup(){

    const alerts = useStoreState(state=>state.userAlerts.alerts);
    const setShowModal = useStoreActions(actions=>actions.userAlerts.setShowModal);
    const removeAlert = useStoreActions(actions=>actions.userAlerts.removeAlert);
    const setShowLoanModal = useStoreActions(actions=>actions.spinwheel.setShowLoanModal);

	const history = useHistory();

    if(!alerts || ( Array.isArray(alerts) && alerts.length===0)){
        return null;
    }

    const closeHandler = () =>{
        setShowModal(false);
    }

    const clickHandler = (alert) =>{
        switch(alert.user_organization_alert_intent){
            case 'new_spinwheel':
                history.push('/employee/accounts');
                break;
            default:
                window.alert('Unknown alert intent: '+alert.user_organization_alert_intent);
        }
        axios.post('/organizations/'+alert.organization_id+'/users/'+alert.user_id+'/alerts/'+alert.user_organization_alert_id+'/read').catch((e)=>{});
        removeAlert(alert.user_organization_alert_id);
        closeHandler();
    }

	return (
		<Popup closeHandler={closeHandler} bodyClass={'alerts-modal'} >
			<div className={'popup-content thrive-description'} >
				<h2>Alerts</h2>
				<div className={'alerts-list'}>
                    {alerts.map((alert)=>{
                        return (
                            <div key={alert.user_organization_alert_id}
                                onClick={()=>{clickHandler(alert)}}
                                className='alert-content'>
                                <div className='alert-message'>
                                    {alert.user_organization_alert_message}
                                </div>
                            </div>
                        )
                    })}
                </div>
			</div>
		</Popup>
	)
}
