import React, {useEffect, useState} from 'react';

import EmployeePopup from "./employeePopup";
import FilterButton from "../../../components/filterButton";
import EmployeeList from "../../../components/lists/employeeList";
import {CSSTransition} from "react-transition-group";

import EmployeeListPlaceholder from "../../../components/placeholders/employeeListPlaceholder";
import axios from "axios";
import {
    ApprovalNeededEnvironments,
    baseApiUrl,
    EMPLOYEE_TYPE,
    organizationUsersUrl,
    UPDATE_EMPLOYEES
} from "../../../_constants";
import {useStoreActions, useStoreState} from "easy-peasy";


import InfiniteScroll from 'react-infinite-scroller';
import {getEnv} from "../../../_helpers";
import TerminatedDuplicatePopup from '../../../components/popups/terminatedDuplicatePopup';

let tempEmployee = null;

export default function Employees({accentColor}){

    const [loading,setLoading] = useState(true);

    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [isScrollLoading,setIsScrollLoading] = useState(false);

    const [employees,setEmployees] = useState([]);
    const [searchTerm,setSearchTerm] = useState('');
    const [showEmployeePopup,setShowEmployeePopup] = useState(false);
    const [selectedEmployee,setSelectedEmployee] = useState(null);
    const [selectedFilters,setSelectedFilters] = useState([]);
    const [filterOptions,setFilterOptions] = useState([
        {label:'Status Active',action:'status',value:'active'},
        {label:'Status Inactive',action:'status', value: 'inactive'}
    ]);
    const setMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const authToken = useStoreState(state=>state.user.token);
    const uploadResponse = useStoreState(state=>state.fileUploadResponse.file);
    const [sortBy,setSortBy] = useState(null);
    const [showTerminatedDuplicate, setShowTerminatedDuplicate] = useState(false);
    const [duplicateResponse,setDuplicateResponse] = useState(null);


    const sortHandler = (column) =>{
        let direction = 'DESC';
        if(sortBy && sortBy.column === column){
            direction = sortBy.direction==='DESC'?'ASC':'DESC';
        }
        setSortBy({column,direction})
    };

    const viewEmployee = (employee) =>{
        setSelectedEmployee(employee);
        setShowEmployeePopup(true);
    };
    const closeEmployeePopup = () =>{
        setShowEmployeePopup(false);
    };

    const handleTerminatedDuplicateSubmit = (shouldResubmit) =>{
        setShowTerminatedDuplicate(false);
        if(shouldResubmit){
            updateEmployee(null,true);
        }
    }

    const updateEmployee = (employee, forceReplace = false) =>{
        if(!employee){
            employee = tempEmployee;
        }
        tempEmployee = employee
        if(employee.roles && employee.roles.length>0) {
            if (employee.roles[0] && employee.roles[0].value) {
                employee.roles = employee.roles.map(role => role.value);
            } else {
                employee.roles = employee.roles.filter(role => role?.role_token).map(role => role.role_token);
            }
        }else{
            employee.roles = [EMPLOYEE_TYPE];
        }
        if(employee.user_organization_role_status === 'inactive'){
            employee.roles = employee.roles.filter(role=>role !== 'employee');
        }else if(!employee.roles.includes('employee')){
            employee.roles.push('employee')
        }
        employee.force_replace = forceReplace;
        let url = organizationUsersUrl(activeOrganization.organization_id) + (employee.user_id?'/'+employee.user_id:'');
        axios.post(url, {user: employee}).then(response => {
            if(!!response.data.duplicate){
                setDuplicateResponse(response.data.duplicate);
                setShowTerminatedDuplicate(true);
                return;
            }
            if (!!response.data.user) {
                tempEmployee = null;
                let newUser = employees.filter(user=>user.user_id===response.data.user.user_id).length<1;
        
                setShowTerminatedDuplicate(false);
                setDuplicateResponse(null);
                closeEmployeePopup();
                if(newUser){
                    setEmployees([response.data.user,...employees]);
                    setMessage({message: 'Employee Added'});
                }else{
                    setEmployees(employees.map(user => {
                        if (user.user_id === response.data.user.user_id) {
                            return response.data.user
                        }
                        return user
                    }));
                    setMessage({message: 'Employee Updated'});
                }
            }
        }).catch(e => {})
    };

    const deleteEmployee = (user) =>{
        axios.delete(organizationUsersUrl(activeOrganization.organization_id)+'/'+user['user_id']+'/employee').then(response=>{
            setEmployees(employees.filter(user => user.user_id != response.data.user_id));
            setMessage({message: 'Employee Deleted'});
            setShowEmployeePopup(false);
        }).catch(e=>{})
    }

    const newEmployee = () =>{
      setSelectedEmployee(null);
      setShowEmployeePopup(true);
    };

    const clearSearchAndFilters = () =>{
        setSearchTerm('');
        setSelectedFilters([]);
        setFilterOptions(filterOptions.map(filter=>{
            filter.selected = false;
            return filter;
        }));
    };

    const scrollLoad = (page) =>{

        if(page>totalPages || isScrollLoading || loading){
            return;
        }
        setIsScrollLoading(true);
        let queryString = '?roles[]=employee';
        queryString += '&page='+page;
        if(selectedFilters.length===1){
            queryString += '&status='+selectedFilters[0].value;
        }
        if(searchTerm.trim().length>0){
            queryString += '&search=' + searchTerm.trim();
        }
        if(sortBy){
            queryString += '&sort=' + sortBy.column;
            queryString += '&direction=' + sortBy.direction;
        }
        setCurrentPage(page);
        axios.get(organizationUsersUrl(activeOrganization['organization_id']) + queryString).then(response=>{
            setEmployees([...employees,...response.data.users]);
            setLoading(false);
        
            setTotalPages(response.data.meta.pages);
            setTimeout(()=>{setIsScrollLoading(false);},0)
        }).catch(e=>{});
    };

    const bulkUpdateStatus = (status,users) =>{
        if(ApprovalNeededEnvironments.includes(getEnv()) && !activeOrganization.application_approved){
            return;
        }
        if(status === 'active'){
            users = users.map(user=>{
                if(!user.roles || user.roles.length <=0){
                    user.roles = [EMPLOYEE_TYPE];
                }
                if (user.roles[0] && user.roles[0].value) {
                    user.roles = user.roles.map(role => role.value);
                } else {
                    user.roles = user.roles.map(role => role.role_token);
                }
                if(!user.roles.includes(EMPLOYEE_TYPE)){
                    user.roles.push(EMPLOYEE_TYPE);
                }
                return user;
            });
        }else{
            users = users.map(user=>{
                if(!user.roles || user.roles.length <=0){
                    user.roles = [];
                }
                if (user.roles[0] && user.roles[0].value) {
                    user.roles = user.roles.map(role => role.value);
                } else {
                    user.roles = user.roles.map(role => role.role_token);
                }
                user.roles = user.roles.filter(role=>role !== EMPLOYEE_TYPE);
                return user;
            });
        }
        setLoading(true);
        axios.post('/organizations/'+activeOrganization.organization_id+'/users/bulk-update?roles[]=employee',{users:users}).then(response=>{
            setEmployees(response.data.users);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{})
    };

    const downloadHandler = () =>{
        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(baseApiUrl+'/organizations/'+activeOrganization.organization_id+'/users/download?auth-token='+response.data.token, '_blank');
            }
        }).catch(e=>{})

    };

    useEffect(()=>{
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationUsersUrl(activeOrganization['organization_id']) + '?roles[]=employee',{cancelToken:cancelToken.token}).then(response=>{
            setEmployees(response.data.users);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
            setLoading(false);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }
    },[]);

    useEffect(()=>{
        if(uploadResponse && uploadResponse.includes(UPDATE_EMPLOYEES)){
            let cancelToken = axios.CancelToken.source();
            axios.get(organizationUsersUrl(activeOrganization['organization_id']) + '?roles[]=employee',{cancelToken:cancelToken.token}).then(response=>{
                setEmployees(response.data.users);
                setCurrentPage(response.data.meta.page);
                setTotalPages(response.data.meta.pages);
                clearSearchAndFilters();
                setLoading(false);
            }).catch(e=>{});
            return ()=>{
                cancelToken.cancel('');
            }
        }
    },[uploadResponse]);

    useEffect(()=>{
        if(loading || isScrollLoading){
            return;
        }
        setFilterOptions(filterOptions.map(option=>{
            option.selected = selectedFilters.filter(soption => soption.value === option.value).length > 0;
            return option;
        }));
        let queryString = '?roles[]=employee';
        if(selectedFilters.length===1){
            queryString += '&status='+selectedFilters[0].value;
        }
        if(searchTerm.trim().length>0){
            queryString += '&search=' + searchTerm.trim();
        }
        if(sortBy){
            queryString += '&sort=' + sortBy.column;
            queryString += '&direction=' + sortBy.direction;
        }
        let cancelToken = axios.CancelToken.source();
        axios.get(organizationUsersUrl(activeOrganization['organization_id']) + queryString,{cancelToken:cancelToken.token}).then(response=>{
            setEmployees(response.data.users);
            setLoading(false);
            setCurrentPage(response.data.meta.page);
            setTotalPages(response.data.meta.pages);
        }).catch(e=>{});
        return ()=>{
            cancelToken.cancel('');
        }


    },[searchTerm,selectedFilters,sortBy]);

    return (
        <>
            {(showEmployeePopup && (!ApprovalNeededEnvironments.includes(getEnv()) || activeOrganization.application_approved)) &&
                    <EmployeePopup
                        accentColor={accentColor}
                        updateHandler={updateEmployee}
                        deleteHandler={deleteEmployee}
                        employee={selectedEmployee}
                        closeHandler={closeEmployeePopup}
                        saveHandler={updateEmployee} />
            }
            {showTerminatedDuplicate && <TerminatedDuplicatePopup closeHandler={()=>{setShowTerminatedDuplicate(false)}} submitHandler={handleTerminatedDuplicateSubmit} response={duplicateResponse} /> }
            <div className={'content-header'}>
                <div className={'title'}>
                    <h1 style={{color:accentColor}} >
                        Employees
                    </h1>
                    <a className={'sub-header'}  href={'#'}>
                        Add, Modify, Delete, and Edit Employees
                    </a>
                </div>
                <div className={'buttons'}>
                    <button onClick={()=>{newEmployee()}} style={{backgroundColor:accentColor}} className={'simple-rounded-button approved-only'}><span className={'add'} /><span>Add New Employee</span></button>
                    <button onClick={downloadHandler} style={{backgroundColor:accentColor}} className={'simple-rounded-button approved-only'}><span>Employee Elections</span></button>
                </div>
            </div>
            <div className={'search-filter-container employees'}>
                <input type={'text'} placeholder={'Search'} value={searchTerm} onChange={(e)=>{setSearchTerm(e.target.value)}} />
                <FilterButton options={filterOptions} changeHandler={setSelectedFilters} />

            </div>

            <CSSTransition
                in={loading}
                timeout={{
                    appear: 500,
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <EmployeeListPlaceholder />
            </CSSTransition>
            <CSSTransition
                in={!loading}
                timeout={{
                    enter: 300,
                    exit: 0,
                }}
                classNames="fade"
                unmountOnExit
            >
                <InfiniteScroll
                    pageStart={1}
                    loadMore={scrollLoad}
                    initialLoad={false}
                    hasMore={(currentPage<totalPages)}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                    >
                    <EmployeeList employees={employees || []}
                                  accentColor={accentColor}
                                  sortHandler={sortHandler}
                                  statusChangeHandler={bulkUpdateStatus}
                                  viewHandler={viewEmployee}
                                  clearHandler={clearSearchAndFilters} />
                </InfiniteScroll>
            </CSSTransition>
        </>
    )
}
