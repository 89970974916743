import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import DocumentDetailsPopup from "./popups/documentDetailsPopup";

export default function FileInput({changeHandler,label,fileKey, fullRow,existingValue,uploadUrl,axiosConfig,error,readonly, optional, descriptions, subText, descriptionsTitle}) {

	const [fileName,setFileName] = useState(existingValue||'');
	const [fileSize,setFileSize] = useState('');
	const inputRef = useRef();
	const [uploading,setUploading] = useState(false);
	const [showDescription,setShowDescription] = useState(false);

	const pickFileHandler = (e) =>{
		clearHandler(e);
		inputRef.current.click();
	}

	const uploadHandler = () =>{
		if(!inputRef.current.value){
			return;
		}
		let files = inputRef.current.files;
		setFileName(files[0].name);
		setUploading(true);

		let formData = new FormData();
		formData.append('file',files[0]);

		axios.post(uploadUrl,formData,axiosConfig).then(response=>{
			setFileName(response.data.file);
			changeHandler(fileKey,response.data.file);
			setFileSize(response.data.file_size);
			setUploading(false);
		}).catch(e=>{
			setFileSize('');
			setFileName('');
			changeHandler(fileKey,'');
			setUploading(false)
		})
	};

	const clearHandler = (e) =>{
		e.preventDefault();
		setFileName('');
		setFileSize('');
		inputRef.current.value = null;
	};


	useEffect(()=>{
		if(existingValue){
			setFileName(existingValue);
		}
	},[existingValue]);

	return(
		<>
			{showDescription && <DocumentDetailsPopup descriptions={descriptions} title={descriptionsTitle} closeHandler={()=>{setShowDescription(false)} } /> }
		<label onClickCapture={(e)=>{if(!e.target.classList.contains('clickable'))e.nativeEvent.preventDefault()}} className={(fullRow?'full-row ':' ') }>
			<span>
			{label + (optional?' (optional)':'')}
			{descriptions && <span onClick={()=>{setShowDescription(true)}} className={'file-descriptions-link clickable'}><img src={'/images/question-blue.svg'} alt={''} /></span>}
			{subText && <p className={'file-sub-text'}>{subText}</p>}
			</span>
			<div className={'file-input-container ' + (error?'invalid':'')}>
				{(!!fileName && !uploading) &&
					<div className={'file-info'}>
						<img src={'/images/attachment.svg'} alt={''} />
						<span className={'name'}>{fileName}</span>
						<span className={'size'}>{fileSize}</span>
					</div>
				}
				{uploading &&
				<div className={'file-info'}>
					<img className={'uploading'} src={"/images/103.gif"} alt={'uploading'} />
				</div>
				}
				{!fileName &&
					<div className={'file-info'}>
						<span className={'file-placeholder'}>No Document Uploaded</span>
					</div>
				}
				{(!!fileName && !readonly) &&
					<span onClick={(e)=>{clearHandler(e)}} className={'clickable file-button remove'}>
						Remove
					</span>
				}
				{(!fileName && !readonly) &&
					<span onClick={(e)=>{pickFileHandler(e)}}  className={'clickable file-button upload'}>
						Upload
					</span>
				}
			</div>
			<input className={'clickable'} onChange={()=>{uploadHandler()}} ref={inputRef} type={'file'} style={{display:'none'}} />
		</label>
		</>
	)

}
