import React, {useEffect, useState} from 'react';
import NumberFormat from "react-number-format";
import WhatIsThriveButton from "../../whatIsThriveButton";
import axios from "axios";
import {contributionSettingsCalculateUrl} from "../../../_constants";

export default function ConfirmContributionsSlide({organization,user,authToken,nextHandler})
{
	const [inputType,setInputType] = useState('flat');
	const [retirementInputType,setRetirementInputType] = useState('percentage');
	const [availableRows,setAvailableRows] = useState([]);
	const [availableContributionTypes,setAvailableContributionTypes] = useState([]);
	const [availableColumns,setAvailableColumns] = useState([]);
	const [calculateCancelToken,setCalculateCancelToken] = useState(axios.CancelToken.source());
	const [updateKey,setUpdateKey] = useState(Math.random());
	const [contributionSettings,setContributionSettings] = useState({
		user_contribution_setting_eligible_compensation: '',
		user_contribution_setting_program_amount: '',
		user_contribution_setting_program_type: '',
		user_contribution_setting_program_contribution_flat: '',
		user_contribution_setting_program_contribution_percentage: '',
		user_contribution_setting_program_match_flat: '',
		user_contribution_setting_program_match_percentage: '',
		user_contribution_setting_program_total_flat: '',
		user_contribution_setting_retirement_amount: '',
		user_contribution_setting_retirement_type: '',
		user_contribution_setting_retirement_contribution_flat: '',
		user_contribution_setting_retirement_contribution_percentage: '',
		user_contribution_setting_retirement_match_flat: '',
		user_contribution_setting_retirement_match_percentage: '',
		user_contribution_setting_retirement_total_flat: '',
		user_contribution_setting_match_total_flat: '',
		user_contribution_setting_match_total_percentage: '',
		user_contribution_setting_contribution_total_flat: '',
		user_contribution_setting_contribution_total_percentage: '',
		user_contribution_setting_contribution_is_match_maximized: false
	});

	const shouldShowTotalContributionColumn = () =>{
		if(!organization){
			return false;
		}
		return organization.plan_token !== 'employer-direct' && organization.plan_token !== 'payroll-deduction';
	};

	useEffect(()=>{
		if(organization.plan_token === 'employer-direct'){
			nextHandler();
			return;
		}
		let newValues = {
			user_contribution_setting_program_amount: 0,
			user_contribution_setting_program_type: 'percentage',
			user_contribution_setting_retirement_amount: 0,
			user_contribution_setting_retirement_type: 'percentage',
			user_contribution_setting_eligible_compensation: 0,
		};
		const newCancelToken = axios.CancelToken.source();
		setCalculateCancelToken(newCancelToken);
		axios.get('/organizations/'+organization.organization_id+'/users/'+user.user_id, {cancelToken:newCancelToken.token, headers: {'Auth-token': authToken}}).then(response=>{
			if(response.data.user){
				setUpdateKey(Math.random());
				axios.post(contributionSettingsCalculateUrl(organization.organization_id,user.user_id),{user_contribution_settings:response.data.user}, {headers: {'Auth-token': authToken}}).then(response=>{
					if(!response.data.user_contribution_settings.user_contribution_setting_eligible_compensation){
						response.data.user_contribution_settings.user_contribution_setting_eligible_compensation = 0;
					}
					setInputType(response.data.user_contribution_settings['user_contribution_setting_program_type']);
					setRetirementInputType(response.data.user_contribution_settings['user_contribution_setting_retirement_type']);
					setContributionSettings(response.data.user_contribution_settings);
					setUpdateKey(Math.random());
				}).catch(e=>{});
			}else{
				axios.post(contributionSettingsCalculateUrl(organization.organization_id,user.user_id),{user_contribution_settings:newValues}, {headers: {'Auth-token': authToken}}).then(response=>{
					if(!response.data.user_contribution_settings.user_contribution_setting_eligible_compensation){
						response.data.user_contribution_settings.user_contribution_setting_eligible_compensation = 0;
					}
					setContributionSettings(response.data.user_contribution_settings);
					setUpdateKey(Math.random());
				}).catch(e=>{});
			}

		}).catch(e=>{});

		return ()=>{
			calculateCancelToken.cancel('');
		}
	},[]);

	useEffect(()=>{
		if(!organization){
			return;
		}
		if(organization.plan_token === 'payroll-deduction'){
			setAvailableColumns( ['your-contribution']);
		}else if(organization.plan_token === 'employer-direct'){
			setAvailableColumns(['employer-contribution']);
		}else{
			setAvailableColumns(['your-contribution','employer-contribution']);
		}
		setAvailableContributionTypes(organization.organization_contribution_setting_contribution_type);
		if(organization.plan_token !== 'flexible-match'){
			setAvailableRows( ['thrive']);
		}else {
			setAvailableRows( [ 'retirement','thrive']);
		}
	},[organization]);

	return(
		<div className={'two-column-signup max-width-container'}>

			<div className={'left'}>
				<h1 className={'title'}>Confirm Contributions</h1>
				<p>
					Please confirm your Monthly Thrive Contributions.
				</p>
				<div dangerouslySetInnerHTML={{__html:organization.organization_retirement_setting_description}}/>
			</div>


			<div className={'right'}>
				<div className={'signup-calculator'}>
					<div>
						<div className={'contribution-block'}>
							{availableRows.includes('retirement') &&
							<div className={'contribution-row'}>
								<div className={'title retirement-title'}>
									<div>
										<img src={'/images/retirement.svg'}/>
										Retirement
									</div>
									{(organization && organization.organization_retirement_setting_logo_url) &&
										<div className={'retirement-info'}>
											<img src={organization.organization_retirement_setting_logo_url} />
											{organization.organization_retirement_setting_title && <span>{organization.organization_retirement_setting_title}</span>}
										</div>
									}
								</div>
								<div className={'contribution-columns '+(availableColumns.length<2?'col-2':'')}>
									{availableColumns.includes('your-contribution') &&
									<label>
										<span className={'double-line'}>Your Monthly Contribution</span>
										<div className={'input-holder split'}>
												<NumberFormat value={contributionSettings.user_contribution_setting_retirement_contribution_percentage}
												              disabled={true}
												              thousandSeparator={true}
												              suffix={'%'}
												              maxLength={5}
												              decimalScale={2}
												              className={'input read-only ' + (retirementInputType === 'percentage' ? 'active-type' : '')}
												              displayType={'text'}
												/>

												<NumberFormat value={contributionSettings.user_contribution_setting_retirement_contribution_flat}
												              disabled={true}
												              thousandSeparator={true}
												              prefix={'$'}
												              decimalScale={2}
												              className={'input read-only ' + (retirementInputType === 'flat' ? 'active-type' : '')}
												              displayType={'text'}
												/>
										</div>
									</label>
									}
									{availableColumns.includes('employer-contribution') &&
									<label>
										<span className={'double-line'}>Employer Monthly Contribution</span>
										<div className={'input-holder split'}>
											<NumberFormat value={contributionSettings.user_contribution_setting_retirement_match_percentage}
											              thousandSeparator={true}
											              className={'input read-only'}
											              suffix={'%'}
											              maxLength={5}
											              decimalScale={2}
											              displayType={'text'}
											/>
											<NumberFormat value={contributionSettings.user_contribution_setting_retirement_match_flat}
											              thousandSeparator={true}
											              className={'input read-only'}
											              prefix={'$'}
											              decimalScale={2}
											              displayType={'text'}
											/>
										</div>
									</label>
									}
									{shouldShowTotalContributionColumn() &&
									<label>
										<span className={'double-line'}>Total Monthly Contribution</span>
										<div className={'input-holder single'}>
											<NumberFormat
												value={contributionSettings.user_contribution_setting_retirement_total_flat}
												thousandSeparator={true}
												className={'input read-only'}
												prefix={'$'}
												decimalScale={2}
												displayType={'text'}
											/>
										</div>
									</label>
									}
								</div>
							</div>
							}
							{availableRows.includes('thrive') &&
							<div className={'contribution-row desktop'}>
								<div className={'title'}><img src={'/images/thrive-contribution.svg'}/>
									<div>
										Thrive
										<WhatIsThriveButton/>
									</div>
								</div>
								<div className={'contribution-columns '+(availableColumns.length<2?'col-2':'')}>
									{availableColumns.includes('your-contribution') &&
									<label>
										<span className={'double-line'}>Your Monthly Contribution</span>
										<div className={'input-holder split'}>
												<NumberFormat value={contributionSettings.user_contribution_setting_program_contribution_percentage}
												              disabled={!availableContributionTypes || !availableContributionTypes.includes('percentage')}
												              thousandSeparator={true}
												              suffix={'%'}
												              maxLength={5}
												              decimalScale={2}
												              className={'input read-only ' + (inputType === 'percentage' ? 'active-type' : '')}
												              displayType={'text'}
												/>
												<NumberFormat value={contributionSettings.user_contribution_setting_program_contribution_flat}
												              disabled={!availableContributionTypes || !availableContributionTypes.includes('flat')}
												              thousandSeparator={true}
												              prefix={'$'}
												              decimalScale={2}
												              className={'input read-only '  + (inputType === 'flat' ? 'active-type' : '')}
												              displayType={'text'}
												/>
										</div>
									</label>
									}
									{availableColumns.includes('employer-contribution') &&
									<label>
										<span className={'double-line'}>Employer Monthly Contribution</span>
										<div className={'input-holder split'}>
											{(organization && organization.plan_token !== 'employer-direct') &&
											<NumberFormat
												value={contributionSettings ? contributionSettings.user_contribution_setting_program_match_percentage : 0}
												thousandSeparator={true}
												className={'input read-only'}
												suffix={'%'}
												maxLength={5}
												decimalScale={2}
												displayType={'text'}
											/>
											}
											<NumberFormat value={contributionSettings?contributionSettings.user_contribution_setting_program_match_flat:0}
											              thousandSeparator={true}
											              className={'input read-only ' + ((organization && organization.plan_token === 'employer-direct')?'single':'')}
											              prefix={'$'}
											              decimalScale={2}
											              displayType={'text'}
											/>
										</div>
									</label>
									}
									{shouldShowTotalContributionColumn() &&
									<label>
										<span className={'double-line'}>Total Monthly Contribution</span>
										<div className={'input-holder single'}>
											<NumberFormat
												value={contributionSettings ? contributionSettings.user_contribution_setting_program_total_flat : 0}
												thousandSeparator={true}
												className={'input read-only'}
												prefix={'$'}
												decimalScale={2}
												displayType={'text'}
											/>
										</div>
									</label>
									}
								</div>
							</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
