import Popup from "./popup";
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from "axios";
import AccountSelector from "../accountSelector";
import { sleep, uniqid, nl2br } from "../../_helpers";
import { SPINWHEEL_MODULES } from "../../hooks/useSpinwheelDim";
import { useStoreActions, useStoreState } from "easy-peasy";

export default function EditAccountNumberPopup({ closeHandler, authToken, account }) {
    const [accountNumber, setAccountNumber] = useState(account.liabilityProfile?.accountNumber || account.spinwheel_account_number || account.plaid_account_mask);
    const [updatedAccountNumber, setUpdatedAccountNumber] = useState(null);
    const [servicer, setServicer] = useState(null);

    useEffect(() => {

        let servicers = [];
        axios.get('/spinwheel/get-spinwheel-servicers', { headers: { 'Auth-token': authToken } }).then(res => {
            servicers = res.data?.spinwheelServicers;
            const servicerAlias = (account.spinwheel_account_servicer_name || account.servicerAliasName || '').toLowerCase();
            if (servicerAlias) {
                let foundServicer = null;
                for (let servicer of servicers) {
                    if (servicerAlias.includes(servicer.spinwheel_servicer_name.toLowerCase())) {
                        foundServicer = { ...servicer };
                    }
                }
                setServicer(foundServicer);
            }
        }).catch(e => {
            console.log(e);
        });


    }, [account, authToken]);

    const submit = useCallback(() => {
        if (!updatedAccountNumber) {
            closeHandler();
        }
        const data = {
            account_id: account.spinwheel_account_student_loan_id,
            account_number: updatedAccountNumber
        };
        axios.post('/spinwheel/update-account-number', data, { headers: { 'Auth-token': authToken } }).then(response => {
            closeHandler(updatedAccountNumber);
        }).catch(e => {
            console.log(e);
        });
    }, [updatedAccountNumber, account, authToken, closeHandler]);

    return (
        <Popup bodyClass={'register-accounts '} closeHandler={closeHandler}>
            <div className={'popup-content register-account-screen'}>
                <h1>Update your account number.</h1>
                <span className={'loan-option-action'} >
                    <b>Reported Account Number:</b> {accountNumber}
                </span>
                {
                    servicer &&
                    <div className={'format-instructions'}>
                        <b>Format Instructions:</b>
                        <span dangerouslySetInnerHTML={{ '__html': nl2br(servicer.spinwheel_servicer_mask_instructions) }} /></div>

                }
                <label>New Account Number
                    <input type={'text'} placeholder={'Enter account number'} value={updatedAccountNumber} onChange={(e) => { setUpdatedAccountNumber(e.target.value) }} />
                </label>
                <button onClick={submit} className={'simple-rounded-button small fixed green'}><span>Update Account</span></button>
            </div>
        </Popup>
    )
}