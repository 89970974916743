import Popup from "./popup";
import React, {useState} from "react";
import axios from 'axios';
import {useStoreActions, useStoreState} from "easy-peasy";
import {Colors} from "../../_constants";

export default function ContactPopup({ organizationId, authToken}) {

	const [subject,setSubject] = useState('');
	const [message,setMessage]= useState('');
	const activeOrganization = useStoreState(state=>state.user.activeOrganization);
	const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
	const setShow = useStoreActions(actions=>actions.modals.setShowContact);

	const submitHandler = () =>{
		if(!activeOrganization){
			showMessage({message:'No Organization assigned',type:'error'})
			return;
		}
		let postData = {
			subject:subject,
			message:message
		}
		const orgId = organizationId?organizationId:activeOrganization.organization_id;
		let headerConf = {};
		if(authToken){
			headerConf = {headers:{'Auth-token':authToken}}
		}
		axios.post('/organizations/'+orgId+'/contact',postData,headerConf).then(response=>{
			closeHandler();
		}).catch(e=>{})
	}

	const closeHandler = () =>{
		setShow(false);
	}

	return (
		<Popup closeHandler={closeHandler} tabbed={false}>
			<div className={'employee-settings popup-content'}>
				<div className={'popup-form message'}>
					<h1 style={{color:Colors.blue}} className={'title'}>Contact Thrive</h1>
					<label className={'full-row'}>
						Subject
						<input
							value={subject}
							onChange={(e) => {
								setSubject(e.target.value)
							}}/>
					</label>

					<label className={'full-row'}>
						Message
						<textarea onChange={(e) => {
							setMessage(e.target.value)
						}} value={message}/>
					</label>


					<div className={'full-row message-buttons'}>

						<button className={'simple-rounded-button small purple'} onClick={(e) => {
							submitHandler(e)
						}}><span>Send Message</span></button>
					</div>

				</div>
			</div>
		</Popup>
	)
}
