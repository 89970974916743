import {useState, useEffect, useMemo} from "react";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";

export default function usePollAlerts(){

    const alerts = useStoreState(state=>state.userAlerts.alerts);
    const lastId = useStoreState(state=>state.userAlerts.lastId);
    const addAlerts = useStoreActions(actions=>actions.userAlerts.addAlerts);
    const user = useStoreState(state=>state.user.user);
    const organization = useStoreState(state=>state.user.activeOrganization);

    const interval = 10 * 1000;

  useEffect(() => {
    if (!user || !organization) {
      return;
    }
    const apiUrl = '/organizations/'+organization.organization_id+'/users/'+user.user_id+'/alerts?last='+(lastId || 0);
    const fetchAlerts = async () => {
      try {
        const response = await axios.get(apiUrl);
        if(response.data?.alerts?.length===0){
            return;
        }

        addAlerts(response.data.alerts);
      } catch (error) {
        console.error('Failed to fetch alerts:', error);
      }
    };

    fetchAlerts();
    const intervalId = setInterval(fetchAlerts, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [addAlerts, interval, lastId, organization, user]);

  return [alerts,lastId];
}