import React, {useEffect, useState} from 'react';
import SingleSelector from "./singleSelector";
import {useStoreActions, useStoreState} from "easy-peasy";
import axios from "axios";
import NumberFormat from "react-number-format";
import {getHttpsApiUrl, uniqid} from "../_helpers";
import {adpConsentLink,adpDisconnectMessage} from "../_constants";
import WarningPopup from "./popups/warningPopup";

export default function AdpDetails() {

    const flatType = 'flat';
    const percentType = 'percentage';
    const contributionTypes = [flatType,percentType];
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);
    const showMessage = useStoreActions(actions=>actions.alertMessage.setMessage);
    const [fullDeductionCodeList,setFullDeductionCodeList] = useState([]);
    const [payDays,setPayDays] = useState([]);
    const [adpConnected,setAdpConnected] = useState(false);
    const [selectedRetirementCodes,setSelectedRetirementCodes] = useState([])
    const [selectedAccumulatorCodes,setSelectedAccumulatorCodes] = useState([])
    const [selectedThriveCodes,setSelectedThriveCodes] = useState([])
    const [showAdpDisconnect,setShowAdpDisconnect] = useState(false);
    const [selectedTaxableCode,setSelectedTaxableCode] = useState(null);
    const [selectedNonTaxableCode,setSelectedNonTaxableCode] = useState(null);
    const [deductionsPerMonth, setDeductionsPerMonth] = useState(null);
    const [updateKey,setUpdateKey] = useState(Math.random);


    useEffect(()=>{
        const cancelToken = axios.CancelToken.source();
        axios.get('/organizations/'+activeOrganization.organization_id+'/application-status', {cancelToken:cancelToken.token}).then(response=>{
            if(response.data.organization){
                setAdpConnected(response.data.organization.adp_account_linked);
                if(response.data.organization.pay_days){
                    setPayDays(response.data.organization.pay_days)
                }
            }
        }).catch(e=>{});



        return ()=>{
            cancelToken.cancel('');
        }
    },[]);


    useEffect(()=>{
        if(adpConnected){
            axios.get('/organizations/'+activeOrganization['organization_id']+'/deduction-codes').then(response=>{
                if(response.data.deduction_codes){
                    setFullDeductionCodeList(response.data.deduction_codes)
                }
                if(Array.isArray(response.data.selected_deduction_codes) && response.data.selected_deduction_codes.length>0){
                    setSelectedRetirementCodes(response.data.selected_deduction_codes.filter(dc=>dc.adp_deduction_code_type === 'retirement'));
                }else{
                    addRetirementCode();
                }

                if(Array.isArray(response.data.selected_accumulator_codes) && response.data.selected_accumulator_codes.length>0){
                    setSelectedAccumulatorCodes(response.data.selected_accumulator_codes);
                    if(response.data.selected_accumulator_codes.filter(ac=>ac.adp_accumulator_code_type === 'gross-pay').length<=0){
                        addAccumulatorCode(true);
                    }
                }else{
                    addAccumulatorCode(true);
                }

                if(response.data.deductions_per_month){
                    setDeductionsPerMonth(response.data.deductions_per_month);
                }

                let thriveDeductionCodes = activeOrganization['contribution_types'].map(ctype=>{
                    return {adp_deduction_code_id:uniqid("",true),is_new:true,adp_deduction_code_type:'thrive',adp_deduction_code_contribution_type:ctype,adp_deduction_code:''}
                });
                if(Array.isArray(response.data.selected_deduction_codes) && response.data.selected_deduction_codes.length>0){
                    let thriveCodes = response.data.selected_deduction_codes.filter(dc=>dc.adp_deduction_code_type==='thrive');

                    response.data.selected_deduction_codes.filter(dc=>dc.adp_deduction_code_type==='thrive').forEach(thriveCode=>{
                        let typeIndex = thriveDeductionCodes.findIndex(td=>td.adp_deduction_code_contribution_type === thriveCode.adp_deduction_code_contribution_type);
                        if(typeIndex>=0){
                            delete thriveDeductionCodes[typeIndex].is_new;
                            thriveDeductionCodes[typeIndex].adp_deduction_code_id = thriveCode.adp_deduction_code_id;
                            thriveDeductionCodes[typeIndex].adp_deduction_code = thriveCode.adp_deduction_code;
                        }else{
                            console.log(thriveCode)
                        }
                    })
                }
                setSelectedThriveCodes(thriveDeductionCodes);

                if(response.data.taxable_code){
                    setSelectedTaxableCode(response.data.taxable_code)
                }
                if(response.data.non_taxable_code){
                    setSelectedNonTaxableCode(response.data.non_taxable_code)
                }

                setUpdateKey(Math.random)
            }).catch(e=>{console.log(e)})
        }
    },[adpConnected])

    const updateThriveCode = (codeId,value)=>{
        const existingIndex = selectedThriveCodes.findIndex((code)=>code.adp_deduction_code_id === codeId);
        if(existingIndex >= 0){
            selectedThriveCodes[existingIndex].adp_deduction_code = value;
        }
        setSelectedThriveCodes(selectedThriveCodes);
        setUpdateKey(Math.random);
    }

    const updateRetirementCode = (codeId,field,value)=>{
        const existingIndex = selectedRetirementCodes.findIndex((code)=>code.adp_deduction_code_id === codeId);
        if(existingIndex >= 0){
            selectedRetirementCodes[existingIndex][field] = value;
        }
        setSelectedRetirementCodes(selectedRetirementCodes);
        setUpdateKey(Math.random);
    }



    const addRetirementCode = () =>{
        const newCode = {adp_deduction_code_id:uniqid("",true),is_new:true,adp_deduction_code_type:'retirement',adp_deduction_code:''};
        if(Array.isArray(selectedRetirementCodes)) {
            setSelectedRetirementCodes([...selectedRetirementCodes,newCode]);
        }else{
            setSelectedRetirementCodes([newCode])
        }
        setUpdateKey(Math.random);
    }

    const removeRetirementCode = (codeId) =>{
        setSelectedRetirementCodes(selectedRetirementCodes.filter((code)=>code.adp_deduction_code_id !== codeId));
        setUpdateKey(Math.random);
    }

    const updateAccumulatorCode = (codeId,field,value)=>{
        if(field === 'adp_accumulator_code' && value){
            value = value.toUpperCase();
        }
        const existingIndex = selectedAccumulatorCodes.findIndex((code)=>code.adp_accumulator_code_id === codeId);
        if(existingIndex >= 0){
            selectedAccumulatorCodes[existingIndex][field] = value;
        }
        setSelectedAccumulatorCodes(selectedAccumulatorCodes);
        setUpdateKey(Math.random);
    }

    const addAccumulatorCode = (addDefault) =>{
        const newCode = {adp_accumulator_code_id:uniqid("",true),is_new:true,adp_accumulator_code_type:'retirement',adp_accumulator_code:''};
        if(addDefault){
            newCode.adp_accumulator_code_type = 'gross-pay';
            newCode.adp_accumulator_code = 'GRS'
        }
        if(Array.isArray(selectedAccumulatorCodes)) {
            setSelectedAccumulatorCodes([...selectedAccumulatorCodes,newCode]);
        }else{
            setSelectedAccumulatorCodes([newCode])
        }
        setUpdateKey(Math.random);
    }

    const removeAccumulatorCode = (codeId) =>{
        setSelectedAccumulatorCodes(selectedAccumulatorCodes.filter((code)=>code.adp_accumulator_code_id !== codeId));
        setUpdateKey(Math.random);
    }

    const disconnectAdp = (shouldDisconnect) =>{
        setShowAdpDisconnect(false);
        if(shouldDisconnect) {
            axios.post('/organizations/' + activeOrganization.organization_id + '/disconnect-adp').then(response => {
                setAdpConnected(false);
            }).catch(e => {
            });
        }
    }

    const submitPayDays = () =>{
        let postData = {
            pay_days:payDays
        };
        axios.post('/organizations/'+activeOrganization.organization_id+'/pay-days', postData).then((response)=>{

        }).catch(e=>{});
    }

    const submitDeductionCodes = () =>{
        let deductionCodes = [...selectedRetirementCodes,...selectedThriveCodes].map(code=>{
            if(code.is_new){
                delete code.adp_deduction_code_id;
                delete code.is_new;
            }
            return code;
        });
        let accumulatorCodes = [...selectedAccumulatorCodes].map(code=>{
            if(code.is_new){
                delete code.adp_accumulator_code_id;
                delete code.is_new;
            }
            return code;
        });

        let postData = {
            deduction_codes:deductionCodes,
            accumulator_codes:accumulatorCodes,
            taxable_code:selectedTaxableCode,
            non_taxable_code:selectedNonTaxableCode,
            deductions_per_month: deductionsPerMonth
        };

        axios.post('/organizations/'+activeOrganization.organization_id+'/deduction-codes', postData).then((response)=>{

        }).catch(e=>{});
    }



    const limitDaysOfMonth = (day) =>{
        return Math.min(Math.max(1,parseInt(day)),28).toString();
    }

    return (
        <>
            {showAdpDisconnect && <WarningPopup closeHandler={disconnectAdp} message={adpDisconnectMessage} />}
            {(activeOrganization && activeOrganization.adp_available) &&
            <>
                <br/>
                <h2>
                    ADP integration
                </h2>
                {!adpConnected &&
                <a className={'simple-rounded-button blue approved-only'} href={adpConsentLink(activeOrganization['organization_id'])} target={'_self'} style={{marginBottom:'20px'}}><span>Connect App to ADP</span></a>
                }
                {(adpConnected ) &&
                <>
                    <button  className={'simple-rounded-button blue'} onClick={(e)=>{setShowAdpDisconnect(true)}}><span>Disconnect From ADP</span></button>
                    <div className={'form-container'}>
                        <div className={'document-list-block field-list'}>
                            {fullDeductionCodeList && fullDeductionCodeList.length>0 &&
                                <>
                            {(activeOrganization.plan_token !== 'employer-direct' && activeOrganization.plan_token !== 'payroll-deduction') &&
                            <>
                            <div style={{width:'100%', margin: '0 15px'}}>
                                <h3>Tax Codes</h3>
                                <p>Used to report taxable and non-taxable employer contributions</p>
                            </div>
                                <label>
                                    Taxable Contribution Code:
                                    <select style={{backgroundColor:'#FFF'}}
                                            value={selectedTaxableCode}
                                            onChange={(e)=>{setSelectedTaxableCode(e.target.value)}}>
                                        <option value={''}>Taxable Code</option>
                                        {fullDeductionCodeList && fullDeductionCodeList.map((dedCode, dcIndex)=>(
                                            <option key={'tax-' + dcIndex} value={dedCode.codeValue}>{dedCode.shortName}</option>
                                        ))}
                                    </select>
                                </label>
                                <label>
                                    Non-Taxable Contribution Code:
                                    <select style={{backgroundColor:'#FFF'}}
                                            value={selectedNonTaxableCode}
                                            onChange={(e)=>{setSelectedNonTaxableCode(e.target.value)}}>
                                        <option value={''}>Non-Taxable Code</option>
                                        {fullDeductionCodeList && fullDeductionCodeList.map((dedCode, dcIndex)=>(
                                            <option key={'notax-' + dcIndex} value={dedCode.codeValue}>{dedCode.shortName}</option>
                                        ))}
                                    </select>
                                </label>
                                </>
                            }
                            {(activeOrganization.plan_token !== 'employer-direct') &&
                            <>
                                <div style={{width:'100%', margin: '0 15px'}}>
                                    <h3>Thrive Codes</h3>
                                    <p>Used to apply thrive deductions to employees payroll</p>
                                </div>
                                    {selectedThriveCodes && selectedThriveCodes.map(thriveCode =>
                                        <label key={thriveCode.adp_deduction_code_id}>
                                            Deduction Code to use for Thrive (<span
                                            style={{textTransform: 'capitalize'}}>{thriveCode.adp_deduction_code_contribution_type} contributions</span>):
                                            <select style={{backgroundColor:'#FFF'}}
                                                    value={thriveCode.adp_deduction_code}
                                                    onChange={(e)=>{console.log(thriveCode.adp_deduction_code_id,thriveCode.adp_deduction_code_contribution_type,e.target.value);updateThriveCode(thriveCode.adp_deduction_code_id,e.target.value)}}>
                                                <option value={''}>Select Code</option>
                                                {fullDeductionCodeList && fullDeductionCodeList.map((dedCode, dcIndex)=>(
                                                    <option key={'notax-' + dcIndex} value={dedCode.codeValue}>{dedCode.shortName}</option>
                                                ))}
                                            </select>
                                        </label>
                                    )}
                                

                                <div style={{margin: '0 15px'}}>
                                    *Thrive deduction codes must <b>not</b> be under any of the following
                                    categories: (Direct Deposit, School District Tax, Medicare Surtax, or Liens)
                                </div>
                            </>
                            }
                            {(activeOrganization.plan_token === 'flexible-match') &&
                                <>
                                <div style={{width:'100%', margin: '0 15px'}}>
                                    <h3 style={{marginTop:30}}>Retirement Codes</h3>
                                    <p>Used during payment match calculations</p>
                                </div>
                                {selectedRetirementCodes.map(code=>
                                    <label key={code.adp_deduction_code_id} style={{display:'flex',width:'100%',gap:20, alignItems:'center', marginTop:0}}>
                                        <select style={{backgroundColor:'#FFF'}}
                                                value={code.adp_deduction_code_contribution_type}
                                                onChange={(e)=>{updateRetirementCode(code.adp_deduction_code_id,'adp_deduction_code_contribution_type',e.target.value)}}>
                                            <option value={''}>Contribution Type</option>
                                            <option value={flatType}>Flat ($)</option>
                                            <option value={percentType}>Percent (%)</option>
                                        </select>
                                        <select style={{backgroundColor:'#FFF'}}
                                                value={code.adp_deduction_code}
                                                onChange={(e)=>{updateRetirementCode(code.adp_deduction_code_id,'adp_deduction_code',e.target.value)}}>
                                            <option value={''}>Retirement Code</option>
                                            {fullDeductionCodeList && fullDeductionCodeList.map((dedCode, dcIndex)=>(
                                                <option key={code.adp_deduction_code_contribution_type + '-' + dcIndex} value={dedCode.codeValue}>{dedCode.shortName}</option>
                                            ))}
                                        </select>
                                        <button className={'remove-button'} onClick={()=>{removeRetirementCode(code.adp_deduction_code_id)}}>Remove</button>


                                    </label>
                                )}
                                <div className={'full-row'}>
                                    <button onClick={addRetirementCode} className={'simple-rounded-button green'}>
                                        <span>Add Retirement Contribution Code</span></button>
                                </div>
                            </>
                            }
                                    {['flexible-match','payroll-deduction', 'employer-match'].includes(activeOrganization.plan_token) &&
                                        <>
                                            <div style={{width:'100%', margin: '0 15px'}}>
                                                <h3 style={{marginTop:30}}>Accumulator Codes</h3>
                                                {['flexible-match', 'employer-match'].includes(activeOrganization.plan_token) ?
                                                    <p>Used during payment match calculations</p>
                                                    :<p>Used to confirm Compensation</p>
                                                }
                                            </div>
                                            {selectedAccumulatorCodes.map(code=>
                                                <label key={code.adp_accumulator_code_id} style={{display:'flex',width:'100%',gap:20, alignItems:'center', marginTop:0}}>
                                                    <select style={{backgroundColor:'#FFF'}}
                                                            value={code.adp_accumulator_code_type}
                                                            onChange={(e)=>{updateAccumulatorCode(code.adp_accumulator_code_id,'adp_accumulator_code_type',e.target.value)}}>
                                                        <option value={''}>Select Type</option>
                                                        {activeOrganization.plan_token === 'flexible-match' &&
                                                            <option value={'retirement'}>Employer Retirement Contribution</option>
                                                        }
                                                        <option value={'gross-pay'}>Gross Pay</option>
                                                    </select>
                                                    {code.adp_accumulator_code_type !== 'gross-pay' ?

                                                        <input type={'text'} value={code.adp_accumulator_code} onChange={(e) => {
                                                            updateAccumulatorCode(code.adp_accumulator_code_id, 'adp_accumulator_code', e.target.value)
                                                        }} placeholder={'Code Value for Employer Retirement Contribution'} />
                                                    :
                                                        <input type={'text'} value={code.adp_accumulator_code} onChange={(e) => {
                                                            updateAccumulatorCode(code.adp_accumulator_code_id, 'adp_accumulator_code', e.target.value)
                                                        }} placeholder={'Code Value for Gross Pay record'} />
                                                    }
                                                    <button className={'remove-button'} onClick={()=>{removeAccumulatorCode(code.adp_accumulator_code_id)}}>Remove</button>


                                                </label>
                                            )}
                                            <div className={'full-row'}>
                                                <button onClick={addAccumulatorCode} className={'simple-rounded-button green'}>
                                                    <span>Add Accumulator Code</span></button>
                                            </div>

                                            <div style={{width:'100%', margin: '0 15px'}}>
                                                <h3 style={{marginTop:30}}>Deductions per month</h3>
                                            </div>
                                            <label className={'full-row'} style={{marginTop:0}}>
                                            <input type={'number'} value={deductionsPerMonth} onChange={(e)=>{setDeductionsPerMonth(e.target.value)}}  />
                                            </label>
                                        </>
                                    }
                            <div className={'full-row right'}>
                                <button onClick={submitDeductionCodes} className={'simple-rounded-button purple'}>
                                    <span>Submit</span></button>
                            </div>
                            </>
                        }
                        </div>
                    </div>
                    {(activeOrganization && payDays && false ) &&
                        //disabling this section for now, payment days aren't being used yet
                    <div className={'form-container'}>
                        <br/>
                        <h2>
                            Payment Days
                        </h2>
                        <div className={'document-list-block field-list'}>
                            {payDays.map((day,i) => {
                                    return (
                                        <label key={i+'-'+day}>
                                            <NumberFormat
                                                placeholder={'day of month'}
                                                decimalScale={0}
                                                thousandSeparator={false}
                                                allowNegative={false}
                                                value={day}
                                                format={limitDaysOfMonth}
                                                onValueChange={(values) => {
                                                    const {formattedValue} = values;
                                                    payDays[i] = formattedValue;
                                                    setPayDays(payDays);
                                                }}/>
                                        </label>
                                    )
                                }
                            )
                            }

                            <div style={{margin:'0 15px'}}>
                                Payments will be initiated on these days
                            </div>
                            <div className={'full-row right'}>
                                <button onClick={submitPayDays} className={'simple-rounded-button purple'}>
                                    <span>Submit</span></button>
                            </div>
                            
                        </div>
                        
                    </div>
                    }
                </>
                }
            </>
            }
        </>
    )
}