import React from 'react'
import {useLocation} from "react-router";
import {baseApiUrl, Colors} from "../../_constants";
import NoResults from "../noResults";
import {useStoreState} from "easy-peasy";
import axios from "axios";

export default function PayrollList({files,linkClass,viewHandler}){
    const location = useLocation();
    const authToken = useStoreState(state=>state.user.token);
    const activeOrganization = useStoreState(state=>state.user.activeOrganization);

    function downloadFile(path){
        axios.get('/auth/generate-token').then(response=>{
            if(response.data.token){
                window.open(path+response.data.token, '_blank');
            }
        }).catch(e=>{})
    }

    return (
        <div className={'payroll-list dashboard-list'}>
            {location.state.layout!=='mobileMax' &&
                <div className={'table-row file-row header ' + ((activeOrganization.plan_token && (activeOrganization.plan_token === 'flexible-match' || activeOrganization.plan_token === 'employer-match'))?' col-6 ':'')}>
                    <span >File name</span>
                    {(activeOrganization.plan_token && (activeOrganization.plan_token !== 'employer-direct')) &&
                        <span>Upload Date</span>
                    }
                    <span >Status</span>
                    {(activeOrganization.plan_token && (activeOrganization.plan_token !== 'employer-direct')) &&
                        <span>Payroll</span>
                    }
                    <span >Payments</span>
                    {(activeOrganization.plan_token && (activeOrganization.plan_token === 'flexible-match' || activeOrganization.plan_token === 'employer-match'))
                        && <span>Match</span>
                    }
                </div>
            }
            {location.state.layout!=='mobileMax' && files.map((file)=>{
                let timeStamp = file.payroll_upload_timestamp || file.contribution_cycle_timestamp;
                return (
                    <div key={file.contribution_cycle_timestamp}
                         className={'table-row  file-row '
                                    + (file.flagged?' flagged ':'')
                                    + ((activeOrganization.plan_token && (activeOrganization.plan_token === 'flexible-match' || activeOrganization.plan_token === 'employer-match'))?' col-6 ':'')
                         }>
                        <span >{file.flagged && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.payroll_upload_file || file.contribution_cycle_title}</span>
                        {(activeOrganization.plan_token && activeOrganization.plan_token !== 'employer-direct' && timeStamp) &&
                        <span>{new Date(timeStamp.replace(' ','T')).toLocaleDateString()}</span>
                        }
                        <span >{file.contribution_cycle_status_status}</span>
                        {(activeOrganization.plan_token && (activeOrganization.plan_token !== 'employer-direct')) &&
                            <span className={'file-download'}>
                                {!file.payroll_upload_timestamp?(
                                        <a>ADP</a>
                                    )
                                    :(  <a className={'payroll'}
                                           onClick={e=>{e.preventDefault();downloadFile(file.payroll_upload_url + '?auth-token=')}}
                                           href={'#'}
                                    >
                                        <span style={{color: Colors.teal}} className={'mdi mdi-file'}/>
                                    </a>)}
                                </span>
                        }
                        <span className={'file-download'}>
                            <a className={'payroll'}
                                onClick={e=>{e.preventDefault();downloadFile(file.contribution_cycle_download_url+'?auth-token=')}}
                                href={'#'}
                                 >
                                    <span style={{color: Colors.teal}} className={'mdi mdi-file'}/>
                                </a>
                        </span>

                        {(activeOrganization.plan_token && (activeOrganization.plan_token === 'flexible-match' || activeOrganization.plan_token === 'employer-match'))
                        && <span className={'file-download'}>
                            <a className={'payroll'}
                                onClick={e=>{e.preventDefault();downloadFile(file.contribution_cycle_match_download_url+'?auth-token=')}}
                                href={'#'}
                                >
                                    <span style={{color: Colors.teal}} className={'mdi mdi-file'}/>
                                </a>
                        </span>
                        }
                    </div>
                )
            })}
            {(location.state.layout==='mobileMax')  && files.map((file)=>{
                let timeStamp = file.payroll_upload_timestamp || file.contribution_cycle_timestamp;
                return (
                    <div key={file.contribution_cycle_timestamp} className={'table-row  file-row tablet ' + (file.flagged?' flagged ':'') + ((activeOrganization.plan_token && (activeOrganization.plan_token === 'flexible-match' || activeOrganization.plan_token === 'employer-match'))?' col-6 ':'')}>
                        <div>
                            <span className={'title'}>File name</span>
                            <span >{file.flagged && <img src={'/images/new-alert.svg'} alt={'new alert'} /> }{file.payroll_upload_file || file.contribution_cycle_title}</span>
                        </div>
                        {(activeOrganization.plan_token && activeOrganization.plan_token !== 'employer-direct' && timeStamp) &&
                        <div>
                            <span className={'title'}>Upload Date</span>
                            <span>{new Date(timeStamp.replace(' ','T')).toLocaleDateString()}</span>
                        </div>
                        }
                        <div>
                            <span className={'title'}>Status</span>
                            <span >{file.contribution_cycle_status_status}</span>
                        </div>
                        {(activeOrganization.plan_token && (activeOrganization.plan_token !== 'employer-direct')) &&
                        <div className={'file-download'}>
                            <span className={'title'}>Payroll</span>
                            <span className={'file-download'}>
                                {!file.payroll_upload_timestamp?(
                                    <a>ADP</a>
                                    )
                                    :( <a className={'payroll'}
                                                                   onClick={e=>{e.preventDefault();downloadFile(file.payroll_upload_url + '?auth-token=')}}
                                                                   href={'#'}
                                >
                                    <span style={{color: Colors.teal}} className={'mdi mdi-file'}/>
                                </a>)}

                                </span>
                        </div>
                        }
                        <div className={'file-download'}>
                            <span className={'title'}>Payments</span>
                            <span className={'file-download'}>
                                <a className={'payroll'}
                                   onClick={e=>{e.preventDefault();downloadFile(file.contribution_cycle_download_url+'?auth-token=')}}
                                   href={'#'}
                                >
                                    <span style={{color: Colors.teal}} className={'mdi mdi-file'}/>
                                </a>
                            </span>
                        </div>

                        {(activeOrganization.plan_token && (activeOrganization.plan_token === 'flexible-match' || activeOrganization.plan_token === 'employer-match'))
                        &&
                            <div className={'file-download'}>
                                <span className={'title'}>Match</span>
                                <span className={'file-download'}>
                                    <a className={'payroll'}
                                         onClick={e=>{e.preventDefault();downloadFile(file.contribution_cycle_match_download_url+'?auth-token=')}}
                                         href={'#'}
                                    >
                                            <span style={{color: Colors.teal}} className={'mdi mdi-file'}/>
                                        </a>
                                </span>
                            </div>
                        }
                    </div>
                )
            })}
            {files.length<=0 && <NoResults />}
        </div>
    );

}


