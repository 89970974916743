import React from 'react';
import {Link} from 'react-router-dom'
import Popup from "./popup";
export default function HasRightfootAccountsPopup({closeHandler})
{

	return (
		<Popup closeHandler={closeHandler} bodyClass={'register-accounts'}>
			<div className={'popup-content register-account-screen manual-loan-message'}>
				<span>One or more of your student loans are no longer supported</span>
				<span>Go to the <Link to={'/employee/accounts'} onClick={e => {
					closeHandler(true)
				}}>'Register Account'</Link> tab and re-register your student loan accounts to continue receiving payments.</span>
			</div>
		</Popup>
	)
}
