import {getBaseApiUrl, getHttpsApiUrl, getBaseUploadApi} from "./_helpers";

export const baseApiUrl = getBaseApiUrl();
export const baseUploadApi = getBaseUploadApi();
export const PLAID_PUBLIC_KEY = '5f369af012315a00115aa996';
export const loginUrl = 'auth/login';
export const validateUrl = 'auth/validate';
export const organizationUsersUrl = (orgId) => {return '/organizations/'+orgId+'/users'};
export const rolesUrl = '/roles';
export const inviteUrl = '/invitations';
export const organizationPayrollUrl = (orgId) => {return '/organizations/'+orgId+'/contribution-cycles'};
export const organizationPayrollUploadUrl = (orgId) => {return baseUploadApi+'/organizations/'+orgId+'/payroll/uploads'};
export const organizationPayrollTemplateUrl = (orgId) => {return '/organizations/'+orgId+'/payroll/uploads/template'};
export const organizationEligibilityUploadUrl = (orgId) => {return baseUploadApi+'/organizations/'+orgId+'/users/upload'};
export const organizationEligibilityTemplateUrl = (orgId) => {return '/organizations/'+orgId+'/users/template'};
export const organizationCycleDownloadUrl = (orgId,fileId) => {return '/organizations/'+orgId+'/election-cycles/'+fileId+'/download'};
export const organizationPendingCycleDownloadUrl = (orgId,fileId) => {return '/organizations/'+orgId+'/election-cycles/pending'};
export const organizationCyclesUrl = (orgId) => {return '/organizations/'+orgId+'/election-cycles'};
export const organizationInvoicesUrl = (orgId) => {return '/organizations/'+orgId+'/invoices'};
export const organizationActivityUrl = (orgId) => {return '/organizations/'+orgId+'/activity'};
export const organizationAnnouncementsUrl = (orgId) => {return '/organizations/'+orgId+'/announcements'};
export const contributionSettingsCalculateUrl = (orgId,userId)=>{return '/organizations/'+orgId+'/users/'+userId+'/contribution-settings/calculate'};
export const contributionSaveSettingsUrl = (orgId,userId)=>{return '/organizations/'+orgId+'/users/'+userId+'/contribution-settings/'};
export const plaidEmployeeTokenUrl = '/plaid/link-token/employee';
export const plaidEmployerTokenUrl = '/plaid/link-token/employer';
export const UPDATE_EMPLOYEES = 'update_employees';
export const UPDATE_PAYROLL = 'update_payroll';
export const EMPLOYEE_TYPE = 'employee';
export const EMPLOYEE_TYPE_NO_EVOSHARE = 'employee-no-evoshare';
export const ADMIN_TYPE = 'employer-admin';
export const EMPLOYER_CONTACT_TYPE = 'employer-contact';
export const HR_TYPE = 'hr';
export const COMPANY_OWNER_TYPE = 'company-owner';

export const adpDisconnectMessage = 'All deductions and payments will be stopped. Are you sure you want to continue?';
//sandbox
export const adpConsentLink = (orgId) => {return 'https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=56aa4e77-88d8-41c6-a746-c3897ba3f617&successUri='+getHttpsApiUrl()+'/adp/adp-consent?organization_id='+orgId};
// export const adpConsentLink = (orgId) => {return 'https://adpapps.adp.com/consent-manager/pending/direct?consumerApplicationID=1feca3e0-04df-4edc-8e8e-55e88be87881&successUri='+getHttpsApiUrl()+'/adp/adp-consent?organization_id='+orgId};


export const ADMIN_USER_TYPES = [
    ADMIN_TYPE,
    HR_TYPE,
    COMPANY_OWNER_TYPE,
    EMPLOYER_CONTACT_TYPE
];

export const EMPLOYEE_USER_TYPES = [
    EMPLOYEE_TYPE,
    EMPLOYEE_TYPE_NO_EVOSHARE
]

export const Colors = {
    orange:'#FC825C',
    lightOrange:'#EBB04A',
    teal: '#17D6C6',
    blue: '#50529E',
    listBlue:'#1480D9'
};

export const tabletMaxWidth = 1300;
export const tabletMedWidth = 1024;

export const Breakpoints = [
    {
        name:'tabletMax',
        width: 1300
    },
    {
        name:'tabletMed',
        width: 1024
    },
    {
        name:'mobileMax',
        width: 760
    }
];

export const Months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

//Add '/invite/test-employee' and '/invite/test-employer' to alwaysAvailableRoutes to test signup while logged in (remove when done testing)
export const alwaysAvailableRoutes = ['/account','/switch'];
export const employerRoutes = ['/employer/','/employer/employees','/employer/payroll','/employer/elections','/employer/invoice','/activity','/messaging','/reports','/users','/company-account','/company-account/:'];
export const employeeRoutes = ['employee/','/employee/activity','/employee/contributions','/employee/accounts','/employee/evoshare'];


export const ownerTitleOptions = [
    {label:'CEO',value:'ceo'},
    {label:'CFO',value:'cfo'},
    {label:'COO',value:'coo'},
    {label:'Managing Member',value:'managing_member'},
    {label:'General Partner',value:'general_partner'},
    {label:'President',value:'president'},
    {label:'Vice President',value:'vice_president'},
    {label:'Treasurer',value:'treasurer'},
    {label:'Other',value:'other'}
];
// TODO: add 'production' back here after spinwheel has been acive for a bit
export const ApprovalNeededEnvironments = [
];
