import React, {useEffect} from 'react';
import Header from "./components/nav/header";
import {Route, Switch, useHistory, useLocation} from "react-router";
import EmployerDashboard from "./screens/employer/employerDashboard";
import Activity from "./screens/employer/activity";
import Messaging from "./screens/employer/messaging";
import Reports from "./screens/employer/reports";
import Users from "./screens/employer/users";
import AccountInfo from "./screens/employer/accountInfo";
import Footer from "./components/footer";
import {useStoreState} from "easy-peasy";
import EmployeeDashboard from "./screens/employee/employeeDashboard";
import {
	employerRoutes,
	alwaysAvailableRoutes,
	employeeRoutes,
	EMPLOYEE_TYPE,
	ADMIN_TYPE,
	EMPLOYEE_TYPE_NO_EVOSHARE, HR_TYPE, ADMIN_USER_TYPES, EMPLOYEE_USER_TYPES, EMPLOYER_CONTACT_TYPE
} from "./_constants";
import CompanyAccountScreen from "./screens/employer/companyAccountScreen";
import {checkRoute} from "./_helpers";

export default function AuthRequiredRoutes(){
	const location = useLocation();
	const history = useHistory();
	const {layout} = location.state;
	const userType = useStoreState(state=>state.user.type);

	useEffect(()=>{

		switch(userType){
			case EMPLOYER_CONTACT_TYPE:
				if(!checkRoute(employerRoutes,location.pathname)) {
					history.push('/employer/');
				} else{
					history.push(location.pathname);
				}
				break;
			case ADMIN_TYPE:
				if(!checkRoute(employerRoutes,location.pathname)) {
					history.push('/employer/');
				} else{
					history.push(location.pathname);
				}
				break;
			case HR_TYPE:
				if(!checkRoute(employerRoutes,location.pathname)) {
					history.push('/employer/');
				} else{
					history.push(location.pathname);
				}
				break;
			case EMPLOYEE_TYPE:
				if(!checkRoute(employeeRoutes,location.pathname)) {
					history.push('/employee/');
				} else{
					history.push(location.pathname);
				}
				break;
			case EMPLOYEE_TYPE_NO_EVOSHARE:
				if(!checkRoute(employeeRoutes,location.pathname)) {
					history.push('/employee/');
				} else{
					history.push(location.pathname);
				}
				break;
			default:
				break;
		}
	},[userType]);
	return (
		<>
			<Header layout={layout}/>
			{(!!userType && ADMIN_USER_TYPES.includes(userType)) &&
				<Switch location={location}>
					< Route path='/activity'>
						<Activity/>
					</Route>
					<Route path='/messaging'>
						<Messaging/>
					</Route>
					<Route path='/reports'>
						<Reports/>
					</Route>
					<Route path='/users'>
						<Users/>
					</Route>

					<Route path='/employer'>
						<EmployerDashboard/>
					</Route>
					<Route path='/account'>
						<AccountInfo/>
					</Route>
					<Route path='/company-account/:error'>
						<CompanyAccountScreen/>
					</Route>
					<Route path='/company-account'>
						<CompanyAccountScreen/>
					</Route>
					<Route path='/'>
						<EmployerDashboard/>
					</Route>
				</Switch>
			}
			{(!!userType && EMPLOYEE_USER_TYPES.includes(userType)) &&
				<Switch location={location}>
					<Route path='/employee'>
						<EmployeeDashboard/>
					</Route>
					<Route path='/account'>
						<AccountInfo/>
					</Route>
					<Route path='/'>
						<EmployerDashboard/>
					</Route>
				</Switch>
			}
		<Footer/>
		</>
	)
}
