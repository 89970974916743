import {action, createStore, persist} from "easy-peasy";

export const store = createStore({
    user:persist({
        user:{},
        type:'',
        email:'',
        displayName:'',
        activeOrganization:null,
        organizations:[],
        hasUnverifiedAccounts:false,
        hasRightfootAccounts:false,
        hasInvalidSpinwheelAccounts:false,
        acknowledgedUnverifiedAccounts:false,
        acknowledgedRightfootAccounts:false,
        sawSpinwheelInvalidPopup:false,
        setType:action((state,payload)=>{
            state.type = payload;
        }),
        setName:action((state,payload)=>{
            state.displayName = payload;
        }),
        setActiveOrganization:action((state,payload)=>{
           state.activeOrganization = payload;
           if(payload) {
               state.hasUnverifiedAccounts = payload.unverified_accounts ? payload.unverified_accounts.length > 0 : false;
                state.hasRightfootAccounts = payload.has_rightfoot;
                state.hasInvalidSpinwheelAccounts = payload.has_invalid_spinwheel;
           }else{
               state.hasUnverifiedAccounts = false;
               state.hasRightfootAccounts = false;
               state.hasInvalidSpinwheelAccounts = false;
           }
            state.acknowledgedUnverifiedAccounts = false;
           state.type = payload?payload.role_token : null;
        }),
        setAcknowledgedUnverifiedAccounts:action((state,payload)=> {
            state.acknowledgedUnverifiedAccounts = true;
        }),
        setAcknowledgedRightfootAccounts:action((state,payload)=> {
            state.acknowledgedRightfootAccounts = true;
        }),
        setSawSpinwheelInvalidPopup:action((state,payload)=> {
            state.sawSpinwheelInvalidPopup = true;
        }),
        resetSpinwheelInvalid:action((state,payload)=> {
            state.hasInvalidSpinwheelAccounts = false;
        }),
        login:action((state,payload)=>{
            state.user = payload;
            state.displayName = payload.user_display_name;
            state.email = payload.user_email;
            state.organizations = payload.user_organization_roles || payload.roles;
        }),
        token:'',
        isLoggedIn:false,
        updateToken:action((state,payload)=>{
            state.token = payload;
            state.isLoggedIn = !!payload;
            if(!payload){
                state.type = '';
                state.email = '';
                state.displayName = '';
                state.activeOrganization = null;
                state.organizations = [];
            }
        })
    }),
    alertMessage:{
        showMessage:false,
        type:'',
        message:'',
        setShowMessage:action((state,payload)=>{
            state.showMessage = payload;
        }),
        setMessage:action((state,payload)=>{
            state.message = payload?payload.message:'';
            state.type = payload?payload.type:'';
            state.showMessage = !!payload;
        })
    },
    userAlerts:{
        showModal:false,
        alerts:[],
        lastId:0,
        setShowModal:action((state,payload)=>{
            state.showModal = payload;
        }),
        setAlerts:action((state,payload)=>{
            state.alerts = payload;
            state.lastId = state.alerts[state.alerts.length-1].user_organization_alert_id;
        }),
        setLastId:action((state,payload)=>{
            state.lastId = payload;
        }),
        addAlerts:action((state,payload)=>{
            const newAlerts = payload.filter((alert)=>{ 
                return state.alerts.findIndex((a)=>{return a.user_organization_alert_id === alert.user_organization_alert_id}) === -1;
            });
            state.alerts = [...state.alerts,...newAlerts];
            state.lastId = state.alerts[state.alerts.length-1].user_organization_alert_id;
        }),
        removeAlert:action((state,payload)=>{
            state.alerts = state.alerts.filter((alert)=>{
                return alert.user_organization_alert_id != payload;
            })
        }),
        reset:action((state,payload)=>{
            state.alerts = [];
            state.lastId = 0;
        })

    },
    modals:{
        showContact:false,
        setShowContact:action((state,payload)=>{
            state.showContact = payload;
        }),
    },
    fileUploadResponse:{
        file:null,
        setFile:action((state,payload)=>{
            state.file = payload + Math.random();
        }),
        reset:action((state,payload)=>{
            state.file = null;
        })
    },
    newestFileForDownload:{
        type:'',
        id:'',
        date:'',
        status:'',
        setFile:action((state,payload)=>{
            state.type=payload.type;
            state.id=payload.id;
            state.date=payload.date;
            state.status = payload.status;
        }),
        reset:action((state,payload)=>{
            state.type = '';
            state.id = '';
            state.date = '';
            state.status = '';
        })
    },
    headerInfo:{
        lastUpdated:'',
        showIgnoreErrorsOption:false,
        setLastUpdated:action((state,payload)=>{
            state.lastUpdated = payload;
        }),
        reset:action((state,payload)=>{
            state.lastUpdated = null;
        }),
        setShowIgnoreErrorsOption:action((state,payload)=>{
            state.showIgnoreErrorsOption = payload;
        })
    },
    forte:{
        termsType:'',
        setTermsType:action((state,payload)=>{
          state.termsType = payload;
        })
    },
    spinwheel:{
        acknowledgedInvalidSpinwheelAccounts:false,
        showLoanModal:false,
        setShowLoanModal:action((state,payload)=>{
            state.showLoanModal = payload;
        }),
        setAcknowledgedInvalidSpinwheelAccounts:action((state,payload)=>{
            state.acknowledgedInvalidSpinwheelAccounts = true;
        }),
    }
});
