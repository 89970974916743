import React, {useState} from 'react';
import {uniqid} from "../../_helpers";

export default function PasswordField({changeHandler, value, keyupHandler}) {

    const [showPassword,setShowPassword] = useState(false);

    return (
        <div className={'input password-field'}>
           <input onKeyUp={keyupHandler} name={uniqid("",true)} type={showPassword?'visible-password':'password'} value={value} onChange={(e) => {
               changeHandler(e.target.value)
           }}/>
            <span onClick={()=>{setShowPassword(!showPassword)}} className={showPassword?'mdi mdi-eye-off':'mdi mdi-eye'} />
        </div>
    )
}