import React, { useState } from "react";
import axios from "axios";

const ID_CONNECT = 'identity-connect';
const DEBT_CONNECT = 'debt-connect';
const LOAN_SERVICERS_LOGIN = 'loan-servicers-login';
const LOAN_LIST = 'loan-list';
export const SPINWHEEL_MODULES = {
	ID_CONNECT,
	DEBT_CONNECT,
	LOAN_SERVICERS_LOGIN,
	LOAN_LIST
}
let tempServicerId = null;
export default function useSpinwheelDim(authToken, setShowManualOptionPopup = () => { }) {
	const [showChooseAccountPopup, setShowChooseAccountPopup] = useState(false);
	const [servicerIds, setServicerIds] = useState([]);
	const [connectionType, setConnectionType] = useState(null);

	async function openDim(moduleName, token, onLoad) {
		setTimeout(()=>{document.body.style.overflow = 'hidden';},0);
		setServicerIds([]);
		tempServicerId = null;
		return new Promise((resolve, reject) => {

			const handler = window.Spinwheel.create({
				containerId: 'loadingCatcher',
				onSuccess: (metadata) => { resolve({ response: metadata, type: 'success' }) },
				onLoad: () => { onLoad()},
				onExit: (metadata) => { resolve({ response: metadata, type: 'exit' }) },
				onEvent: (event) => {

					if(event.eventName === 'SERVICER_SELECTED'){
						//this event fires everytime a servicer is clicked on, so only saving temporarily
						tempServicerId =event.metadata.servicerId;
					}
					if(event.eventName === 'LOAN_CONNECTION_SUCCESS'){
						// this fires after logging into servicer, the user can continue to log into other servicers before closing
						setServicerIds([...servicerIds,tempServicerId]);
						tempServicerId = null;
					}
					if(event.eventName === 'UNINTEGRATED_LINK_CLICKED' || event.eventName === 'IDENTITY_NOT_CONNECTED'){
						resolve({ response: null, type: 'error' })
					}
				},
				onError: (metadata) => { resolve({ response: metadata, type: 'error' }) },
				onResize: (metadata) => {  },
				dropinConfig: {
					'module': moduleName,
					'token': token,
					verificationStrategy: 'SMS_OTP'
				}
			});

			handler.open()
		});
	}


	async function initSpinwheel(forceNew=false,useIdConnect = false) {

		let url = '/spinwheel/get-dim-token';
		if(forceNew){
			url += '?forceNew=true';
		}


		document.body.style.cursor = 'wait';
		document.body.style.pointerEvents = 'none';
		const tokenResp = await axios.get(url, {headers:{'Auth-token': authToken}});
		document.body.style.cursor = 'default';
		document.body.style.pointerEvents = 'unset';
		if(!tokenResp.data?.data?.token){
			setShowChooseAccountPopup(true);
			return;
		}
		const loadingCatcher = document.createElement('div');
		loadingCatcher.classList.add('loading-catcher');
		loadingCatcher.classList.add('placeholder-holder')
		loadingCatcher.style.position = 'fixed';
		loadingCatcher.style.top = '0';
		loadingCatcher.style.left = '0';
		loadingCatcher.style.width = '100%';
		loadingCatcher.style.height = '100%';
		loadingCatcher.style.zIndex = '9999';
		loadingCatcher.style.cursor = 'wait';
		loadingCatcher.id = 'loadingCatcher';
		document.body.appendChild(loadingCatcher);
		const onLoad = () => {
			loadingCatcher.classList.remove('placeholder-holder');
		}

		let response = null;
		if(!useIdConnect){
			setConnectionType(LOAN_SERVICERS_LOGIN);
			response = await openDim(SPINWHEEL_MODULES.LOAN_SERVICERS_LOGIN, tokenResp.data.data.token, onLoad);

			switch (response.type) {
				case 'success':
					//skipping right to getting the loans
					// await axios.post('/spinwheel/on-auth-success', { metadata: response.response }, {headers:{'Auth-token': authToken}})
					break;
				case 'exit':
					setShowChooseAccountPopup(false);
					break;
				case 'error':
					useIdConnect = true;
					break;
				default:
					break;
			}
		}
		if(useIdConnect){
			setConnectionType(ID_CONNECT);
			const idConnectedResp = await axios.get('/spinwheel/get-identity-connected', {headers:{'Auth-token': authToken}});
			if(idConnectedResp.data.connected) {
				loadingCatcher.remove();
				setTimeout(()=>{document.body.style.overflow = 'scroll';},0);
				setShowChooseAccountPopup(true);
			} else {
				response = await openDim(SPINWHEEL_MODULES.ID_CONNECT, tokenResp.data.data.token, onLoad);
				switch (response.type) {
					case 'success':
						//skipping right to getting the loans
						// await axios.post('/spinwheel/on-auth-success', { metadata: response.response }, {headers:{'Auth-token': authToken}})
						break;
					case 'exit':
						break;
					case 'error':
						response = await openDim(SPINWHEEL_MODULES.DEBT_CONNECT, tokenResp.data.data.token, onLoad);
						break;
					default:
						break;
				}
			}
		}

		loadingCatcher.remove();
		setTimeout(()=>{document.body.style.overflow = 'scroll';},0);
		if (response?.type === 'success') {
			setShowChooseAccountPopup(true);
		}

	}

	async function spinwheelLoanCloseHandler(newAccount, linkedAccounts, newAccountNumberUpdate = null) {

		if (!newAccount) {
			setShowChooseAccountPopup(false);
			return [linkedAccounts, false];
		}
		try {
			const response = await axios.post('/spinwheel/register-account', {account:newAccount, accountNumberUpdate: newAccountNumberUpdate}, {headers:{'Auth-token': authToken}}).catch(e => console.log(e));
			if(!response?.data?.spinwheel_account_id){
				setShowChooseAccountPopup(false);
				return [linkedAccounts, false];
			}
			newAccount.spinwheel_account_id = response.data.spinwheel_account_id;
			const accountsChanged = linkedAccounts.filter(acc => acc.spinwheel_account_id === response.data.spinwheel_account_id).length === 0;
			if (accountsChanged) {
				linkedAccounts = [newAccount, ...linkedAccounts];
			}
			setTimeout(() => { document.body.style.overflow = 'scroll'; });
			setShowChooseAccountPopup(false);
			return [linkedAccounts, accountsChanged]
		} catch (e) {
			console.log(e);
			setTimeout(() => { document.body.style.overflow = 'scroll'; });
			setShowChooseAccountPopup(false);
		}
		return [linkedAccounts, false];

	};

	return [showChooseAccountPopup, initSpinwheel, spinwheelLoanCloseHandler, servicerIds, connectionType];
}
